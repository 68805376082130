// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics')
  },
  {
    title: 'customers',
    path: '/dashboard/customers',
    icon: icon('ic_user')
  },
  {
    title: 'transactions',
    path: '/dashboard/transactions',
    icon: icon('ic_receipt')
  },
  {
    title: 'invoices',
    path: '/dashboard/invoices',
    icon: icon('ic_cart')
  },
  {
    title: 'services',
    path: '/dashboard/services',
    icon: icon('ic_services')
  },
  {
    title: 'requests',
    path: '/dashboard/requests',
    icon: icon('ic_assignment')
  },
  {
    title: 'packages',
    path: '/dashboard/packages',
    icon: icon('ic_package')
  },
  {
    title: 'contacts',
    path: '/dashboard/contacts',
    icon: icon('ic_contact')
  },
  {
    title: 'setting',
    path: '/dashboard/settings',
    icon: icon('ic_settings')
  }
];

export default navConfig;
