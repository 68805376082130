import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import formatCurrency from '../../utils/formatCurrency';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  minHeight: 250,
  borderRadius: 16,
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.53)),
  url('/assets/images/card-pattern1.png')`
}));

const StyledContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: 'white',
  height: '100%'
}));

function EarningCard(props) {
  const { wallet } = props;
  const balance = wallet?.balance || 0;

  return (
    <StyledCard>
      <StyledContent>
        {/* <Typography variant="overline" sx={{ color: 'white', fontWeight: 'lighter' }}>
          Earnings
        </Typography> */}
        <Box sx={{ textAlign: 'center', justifyContent: 'center', my: 2 }}>
          <Typography variant="h6">Balance</Typography>
          <Typography variant="h3">{formatCurrency(balance)}</Typography>
        </Box>
      </StyledContent>
    </StyledCard>
  );
}

export default EarningCard;

EarningCard.propTypes = {
  wallet: PropType.object
};
