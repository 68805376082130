import { useEffect, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
// import { useNavigate } from 'react-router-dom';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Drawer from '@mui/material/Drawer';
import Empty from '../empty';
import { fToNow } from '../../utils/formatTime';
import { useSWRFetch } from '../../hooks';
import Iconify from '../iconify';
import { colorVariant, formatCurrency, iconVariant } from 'src/utils';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { TransactionCard } from '../cards';

const drawerWidth = 460;

const StyledDateGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  height: '100% !important',
  //   minHeight: 350,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    fontWeight: 400
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  marginRight: 5,
  [theme.breakpoints.down('sm')]: {
    width: 20,
    height: 20
  }
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

const AdvanceToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton sx={{ color: 'text.primary' }} />
    <GridToolbarExport sx={{ color: 'text.primary' }} />
  </GridToolbarContainer>
);

function TransactionList() {
  // const navigate = useNavigate();
  const [openDetails, setOpenDetails] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { data, loading } = useSWRFetch(`/admin/transactions?page=${pageIndex}&limit=${pageLimit}`);
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  useEffect(() => {
    if (data) {
      if (data?.docs?.length) {
        setFilteredTransactions(data?.docs);
      }
    }
  }, [data]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (data?.docs?.length) {
        setFilteredTransactions(data?.docs);
      }
    }
  }, [filterName]);

  const columns = [
    {
      field: 'user',
      headerName: 'Customer',
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StyledAvatar>
            <Avatar alt={params?.value?.username} src={params?.value?.photoUrl} />
          </StyledAvatar>
          <StyledTypography variant="body1">{params?.value?.username}</StyledTypography>
        </Stack>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <StyledAvatar
            sx={{ bgcolor: colorVariant(params?.value) === 'success' ? '#8bc34a' : '#ff5252' }}
          >
            <Iconify icon={iconVariant(params?.value)} sx={{ color: 'white' }} />
          </StyledAvatar>
          <StyledTypography variant="body1">{params?.row?.message}</StyledTypography>
        </Stack>
      )
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'left',
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <StyledTypography variant="body1">{formatCurrency(params?.value)}</StyledTypography>
      )
    },
    {
      field: 'reference',
      headerName: 'Reference',
      maxWidth: 250,
      flex: 1
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      maxWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <StyledTypography variant="body1">{fToNow(params?.value)}</StyledTypography>
      )
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      headerAlign: 'right',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Iconify icon="ion:receipt" width={24} />}
          onClick={() => {
            setSelected(params.row);
            setOpenDetails(true);
          }}
          label="View"
          showInMenu
        />
      ]
    }
  ];

  const handlePageChange = (value) => {
    if (data?.hasNextPage || data?.hasPrevPage) {
      setPageIndex(value + 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageLimit(value);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelected(null);
  };

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(data?.docs || [], ['reference', 'amount', 'user.username'], {
        caseSensitive: false
      });
      const result = searcher.search(filterName);
      setFilteredTransactions(result);
    } else {
      setFilteredTransactions(data?.docs || []);
    }
  };

  return (
    <>
      <UserListToolbar
        filterName={filterName}
        onFilterName={(e) => setFilterName(e.target.value)}
      />

      <StyledDrawer anchor="right" open={openDetails} onClose={handleCloseDetails}>
        <DrawerHeader>
          <IconButton onClick={handleCloseDetails}>
            <Iconify icon="fe:close" />
          </IconButton>
        </DrawerHeader>
        <TransactionCard data={selected} />
      </StyledDrawer>

      <StyledDateGrid
        rows={filteredTransactions}
        columns={columns}
        loading={loading}
        components={{
          Toolbar: AdvanceToolbar,
          NoRowsOverlay: () => <Empty text="No Transactions Found" />,
          LoadingOverlay: () => <LinearProgress color="secondary" />
        }}
        autoHeight
        disableColumnSelector={false}
        checkboxSelection={false}
        pagination
        paginationMode="server"
        page={pageIndex - 1}
        pageSize={pageLimit}
        rowsPerPageOptions={[10, 20, 30]}
        onPageSizeChange={handlePageSizeChange}
        rowCount={data?.totalDocs || 10}
        onPageChange={(value) => handlePageChange(value)}
      />
    </>
  );
}

export default TransactionList;
