import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import { fDate } from 'src/utils/formatTime';
import Empty from '../empty';

const StyledCard = styled(Card)(({ theme }) => ({
  height: 'auto',
  padding: 0
}));

const StyleCardContent = styled(CardContent)(({ theme }) => ({
  textAlign: 'center',
  //   height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0
}));

const ColoredTypography = styled(Typography)(({ theme }) => ({
  color: 'text.primary',
  fontWeight: 'bolder',
  textTransform: 'capitalize'
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '4px solid currentColor',
      content: '""'
    }
  }
}));

const nonDisplayList = [
  'fullName',
  'bank',
  'countryCode',
  'debitCard',
  'hasBoarded',
  'internationalFormat',
  'nationalFormat',
  'password',
  'photoUrl',
  'deviceToken',
  'updatedAt',
  'wallet',
  'username',
  'referrer',
  'device',
  '_id'
];

const dateList = ['createdAt', 'dob'];
const booleanList = ['isEmailVerified', 'isPhoneVerified', 'active'];

ProfileCard.propTypes = {
  profile: PropType.object
};

function ProfileCard({ profile }) {
  return (
    <StyledCard variant="outlined">
      <StyleCardContent sx={{ py: 3 }}>
        {profile ? (
          <>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: profile?.active ? '#8bc34a' : '#ff5252',
                  color: profile?.active ? '#8bc34a' : '#ff5252'
                },
                margin: 'auto'
              }}
            >
              <Avatar
                src={profile?.photoUrl}
                alt={profile?.username}
                sx={{ width: 100, height: 100 }}
              />
            </StyledBadge>
            <ColoredTypography variant="h4" sx={{ my: 1 }}>
              {profile?.username}
            </ColoredTypography>
            <List sx={{ width: '100%' }}>
              {Object.keys(profile)?.map((keyName, index) => {
                const value = profile[keyName];
                if (!nonDisplayList.includes(keyName)) {
                  return (
                    <ListItem
                      key={index}
                      divider
                      secondaryAction={
                        dateList.includes(keyName) ? (
                          <ListItemText primary={fDate(value)} />
                        ) : booleanList.includes(keyName) ? (
                          <Chip
                            label={value ? 'active' : 'Pending'}
                            color={value ? 'success' : 'warning'}
                            sx={{ color: value ? 'success' : 'warning' }}
                            variant="outlined"
                          />
                        ) : (
                          <ListItemText primary={value} />
                        )
                      }
                    >
                      <ListItemText secondary={keyName} />
                    </ListItem>
                  );
                }
                if (keyName === 'device') {
                  return Object.keys(value)?.map((deviceKey, deviceIndex) => (
                    <ListItem
                      key={deviceIndex}
                      divider
                      secondaryAction={<ListItemText primary={value[deviceKey]} />}
                    >
                      <ListItemText secondary={`device ${deviceKey}`} />
                    </ListItem>
                  ));
                }
                return <div key={index} />;
              })}
            </List>
          </>
        ) : (
          <Empty text="Nothing to display" />
        )}
      </StyleCardContent>
    </StyledCard>
  );
}

export default ProfileCard;
