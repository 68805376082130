import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { colorVariant, formatCurrency } from '../../utils';
import formatDate from '../../utils/formatDate';
import Label from '../label';
import ProfileCard from './ProfileCard';

const StyledCard = styled(Card)(({ theme }) => ({
  height: 'auto',
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)'
  }
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'text.primary',
  textTransform: 'capitalize'
}));

const Item = ({ keyName, value, bold, fontSize = 14 }) => (
  <ListItem
    divider
    secondaryAction={
      <StyledTypography
        sx={{ fontSize, color: 'text.primary', fontWeight: bold ? 'bolder' : 'normal' }}
      >
        {value}
      </StyledTypography>
    }
  >
    <ListItemText
      primary={
        <StyledTypography
          sx={{ fontSize, color: 'text.secondary', fontWeight: bold ? 'bolder' : 'normal' }}
        >
          {keyName}
        </StyledTypography>
      }
    />
  </ListItem>
);

TransactionCard.propTypes = {
  data: PropType.object
};

function TransactionCard(props) {
  const { data } = props;

  return (
    <Box sx={{ overflow: 'auto' }}>
      <ProfileCard profile={data?.user} />
      <StyledCard sx={{ my: 5 }}>
        <StyledCardContent>
          <Box>
            <Item keyName="Amount" value={formatCurrency(data?.amount)} fontSize={18} bold />
            <Item
              keyName="Transaction Status"
              value={
                <Label variant="ghost" color={colorVariant(data?.status)}>
                  {data?.status}
                </Label>
              }
            />
            <Item keyName="channel" value={data?.channel} />
            <Item keyName="currency" value={data?.currency} />
            <Item keyName="reference" value={data?.reference} />
            <Item keyName="message" value={data?.message} />
            <Item keyName="Transaction Type" value={data?.type} />
            <Item keyName="Date" value={formatDate(data?.createdAt)} />
          </Box>
        </StyledCardContent>
      </StyledCard>
    </Box>
  );
}

export default TransactionCard;
