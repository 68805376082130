// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { InvoiceList } from 'src/components/list';
import Page from 'src/components/page';

function Invoices() {
  return (
    <Page title="Invoices" sx={{ height: '100%' }}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Invoices
          </Typography>
        </Stack>

        <Card sx={{ height: '100%' }}>
          <InvoiceList />
        </Card>
      </Container>
    </Page>
  );
}

export default Invoices;
