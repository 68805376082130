import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

const AmountInput = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator=","
      allowLeadingZeros
      valueIsNumericString
      prefix="₦"
    />
  );
});

export default AmountInput;

AmountInput.displayName = 'amountInput';

AmountInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
