import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout, { CustomerLayout } from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import { Login } from '../pages/auth';
import {
  Contacts,
  CustomerDetail,
  Customers,
  Invoices,
  Overview,
  Packages,
  Requests,
  Services,
  Settings,
  Transactions
} from 'src/pages/dashboard';
import { PageNotFound } from 'src/pages/public';

// ----------------------------------------------------------------------

export default function Router({ isAuth, profile, loading, mutate }) {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isAuth ? (
        <DashboardLayout loading={loading} profile={profile} />
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <Overview profile={profile} /> },
        {
          path: 'customers',
          element: <CustomerLayout />,
          children: [
            {
              path: 'all',
              element: <Customers />
            },
            {
              path: 'detail',
              element: <CustomerDetail />
            }
          ]
        },
        { path: 'transactions', element: <Transactions /> },
        { path: 'invoices', element: <Invoices /> },
        { path: 'services', element: <Services /> },
        { path: 'requests', element: <Requests /> },
        { path: 'packages', element: <Packages /> },
        { path: 'contacts', element: <Contacts /> },
        { path: 'Settings', element: <Settings profile={profile} mutate={mutate} /> }
      ]
    },
    {
      path: '/',
      element:
        !isAuth && !profile ? <SimpleLayout loading={loading} /> : <Navigate to="/dashboard" />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: 'login', element: <Login mutate={mutate} /> },
        { path: '404', element: <PageNotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />
    }
  ]);

  return routes;
}
