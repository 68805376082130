// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { RequestList } from 'src/components/list';
import Page from 'src/components/page';

function Requests() {
  return (
    <Page title="Requests" sx={{ height: '100%' }}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Requests
          </Typography>
        </Stack>

        <Box sx={{ height: '100%' }}>
          <RequestList />
        </Box>
      </Container>
    </Page>
  );
}

export default Requests;
