import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ContactList, SubscriberList } from 'src/components/list';
import Page from 'src/components/page';
import { TabPanel } from 'src/components/tab';

const tabComponent = (tab) => (
  <Box sx={{ height: '100%' }}>{tab === 'Tickets' ? <ContactList /> : <SubscriberList />}</Box>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const tabs = ['Tickets', 'Subscribers'];

function Contacts() {
  const [tab, setTab] = useState(0);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };
  return (
    <Page title="Contacts" sx={{ height: '100%' }}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contacts
          </Typography>
        </Stack>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper',
            borderRadius: 1
          }}>
          <Tabs value={tab} onChange={handleChange} aria-label="tab">
            {tabs.map((item, index) => (
              <Tab key={index} label={item} {...a11yProps(0)} />
            ))}
          </Tabs>
        </Box>
        {tabs?.map((item, index) => (
          <TabPanel key={item} value={tab} index={index}>
            {tabComponent(item)}
          </TabPanel>
        ))}
      </Container>
    </Page>
  );
}

export default Contacts;
