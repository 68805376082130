import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { formatCurrency } from 'src/utils';
import Iconify from '../iconify';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)'
  }
}));

const StyleCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

function PackageItemCard({ data }) {
  const isRecommended = () => data?.name === 'premium plan';

  return (
    <StyledCard
      sx={{ bgcolor: isRecommended() ? 'secondary.main' : 'background.paper' }}
      elevation={4}
    >
      <StyleCardContent>
        <Box sx={{ flexGrow: 1, textAlign: 'start' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography
              variant="h6"
              textTransform="capitalize"
              sx={{ color: isRecommended() ? 'white' : 'text.primary' }}
            >
              {data?.name}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h3" sx={{ color: isRecommended() ? 'white' : 'text.primary' }}>
              {formatCurrency(data?.price)}
            </Typography>
            <Typography variant="body2" sx={{ color: isRecommended() ? 'white' : 'text.disabled' }}>
              / {data?.interval || 'Monthly'}
            </Typography>
          </Stack>
          <Typography
            variant="subtitle2"
            fontWeight={300}
            lineHeight={1.4}
            sx={{ color: isRecommended() ? 'white' : 'text.secondary', my: 1 }}
          >
            {data?.desc}
          </Typography>
          {data?.note && <Alert severity="warning">{data?.note}</Alert>}
          <List dense>
            {data?.features?.map((item, index) => (
              <ListItem sx={{ py: 0 }} key={index}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <Iconify
                    icon="bxs:check-circle"
                    sx={{ color: isRecommended() ? '#4ed027' : '#6eff43' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item}
                  sx={{ color: isRecommended() ? 'white' : 'text.primary' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </StyleCardContent>
    </StyledCard>
  );
}

export default PackageItemCard;

PackageItemCard.propTypes = {
  data: PropType.object
};
