import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import { useState } from 'react';

const ChipItem = ({ chip, index, selected, onSelect, onDelete }) => {
  const color = selected?.index === index ? 'secondary' : 'default';
  return (
    <Chip
      label={chip}
      color={color}
      variant="outlined"
      onClick={() => onSelect(chip, index)}
      onDelete={() => onDelete(index)}
    />
  );
};

function ChipInput({ chips, label, selected, onSelect, onDelete, ...others }) {
  return (
    <TextField
      {...others}
      id="input-with-icon-textfield"
      variant="outlined"
      label={label}
      placeholder={`Add more ${label}`}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ display: 'contents' }}>
            {chips?.map((chip, index) => (
              <ChipItem
                key={index}
                chip={chip}
                index={index}
                selected={selected}
                onDelete={onDelete}
                onSelect={onSelect}
              />
            ))}
          </InputAdornment>
        )
      }}
      sx={{
        '& .MuiInputBase-root': {
          display: 'inline-grid',
          padding: 2
        }
      }}
    />
  );
}

export default ChipInput;

ChipInput.defaultProps = {
  chips: []
};

ChipInput.propTypes = {
  chips: PropTypes.array,
  label: PropTypes.string.isRequired,
  selected: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};
