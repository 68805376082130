import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Page from 'src/components/page';

function CustomerLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/dashboard/customers') {
      navigate('/dashboard/customers/all');
    }
  }, [pathname]);

  return (
    <Page title="Customers" sx={{ height: '100%' }}>
      <Outlet />
    </Page>
  );
}

export default CustomerLayout;
