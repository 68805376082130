import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import FuzzySearch from 'fuzzy-search';
import toast from 'react-hot-toast';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Empty from '../empty';
import Scrollbar from '../scrollbar';
import { useSWRFetch } from 'src/hooks';
import { SupportCard } from '../cards';
import APIService from 'src/service';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { FilterForm } from '../forms';

const StyledItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5),
  marginBottom: 5,
  borderRadius: 8
}));

const groups = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Resolved',
    value: 'resolved'
  },
  {
    label: 'Closed',
    value: 'closed'
  }
];

const Item = (props) => {
  const { index, selectedIndex, setSelectedIndex, setSelected } = props;
  const isSelected = selectedIndex === index;

  const handleClick = () => {
    setSelectedIndex(index);
    setSelected(props);
  };

  return (
    <StyledItemButton
      sx={{
        border: isSelected ? '2px solid #54D62C' : 'none',
        bgcolor: isSelected ? 'rgb(182 226 184 / 25%)' : 'background.paper'
      }}
      onClick={handleClick}
    >
      <ListItemAvatar>
        <Avatar src={props?.user?.photoUrl} alt={props?.user?.fullName} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            sx={{
              fontWeight: 'bolder',
              textTransform: 'capitalize'
            }}
          >{`${
            !props?.user?.fullName?.includes('undefine')
              ? props?.user?.fullName
              : props?.user?.username
          } #${props?.ticketId}`}</Typography>
        }
        secondary={props?.subject}
      />
    </StyledItemButton>
  );
};

function ContactList() {
  const [loading, setLoading] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [status, setStatus] = useState('all');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selected, setSelected] = useState(null);
  const { data: supports, mutate } = useSWRFetch('/admin/supports');
  const [filteredSupports, setFilteredSupports] = useState([]);

  useEffect(() => {
    if (supports?.length) {
      setFilteredSupports(supports);
      setSelected(supports[0]);
    }
  }, [supports]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (supports?.length) {
        setFilteredSupports(supports);
      }
    }
  }, [filterName]);

  useEffect(() => {
    if (status && supports?.length) {
      if (status !== 'all') {
        const filteredData = supports?.filter((item) => item?.status === status);
        setFilteredSupports(filteredData);
        if (filteredData?.length) {
          setSelected(filteredData[0]);
        } else {
          setSelected(null);
        }
      } else {
        setFilteredSupports(supports);
      }
    }
  }, [status]);

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(
        supports || [],
        ['ticketId', 'user.username', 'status', 'emailAddress', 'subject'],
        {
          caseSensitive: false
        }
      );
      const result = searcher.search(filterName);
      setFilteredSupports(result);
      setSelected(result[0]);
    } else {
      setFilteredSupports(supports || []);
    }
  };

  const handleAction = (action) => {
    // 'resolved', 'closed'
    const response = APIService.update('/admin/support', selected?.id, { status: action });
    toast.promise(response, {
      loading: 'Loading',
      success: (res) => {
        mutate();
        setLoading(false);
        setSelected({ ...selected, status: action });
        return `Ticked ${action} Successfully!`;
      },
      error: (err) => {
        setLoading(false);
        return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
      }
    });
  };

  return (
    <>
      <Stack
        component={Card}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, pr: 3 }}
      >
        <UserListToolbar
          filterName={filterName}
          onFilterName={(e) => setFilterName(e.target.value)}
        />
        <FilterForm value={status} setValue={setStatus} options={groups} />
      </Stack>
      <Grid spacing={2} container>
        <Grid xs={12} sm={4} sx={{ height: '100%', maxHeight: 700, overflow: 'auto' }} item>
          <Scrollbar component={List}>
            {filteredSupports?.map((item, index) => (
              <Item
                key={index}
                setSelected={setSelected}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                index={index}
                {...item}
              />
            ))}
          </Scrollbar>
        </Grid>
        <Grid xs={12} sm={8} item>
          {selected ? (
            <SupportCard data={selected} loading={loading} handleAction={handleAction} />
          ) : (
            <Empty text="No selected" />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ContactList;
