import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import FuzzySearch from 'fuzzy-search';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Empty from '../empty';
import Iconify from '../iconify';
import { useSWRFetch } from 'src/hooks';
import { AdminForm, CreateUserForm } from '../forms';
import LinearProgress from '@mui/material/LinearProgress';
import { AdminCard } from '../cards';
import List from '@mui/material/List';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { ConfirmModal, CustomModal, FormModal } from '../modal';
import APIService from 'src/service';
import LoadingBackdrop from '../loading/Backdrop';

const drawerWidth = 460;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

function AdminList() {
  const [requestLoading, setRequestLoading] = useState(false);
  const [openCreateForm, setOpenCreateForm] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const { data, mutate, loading } = useSWRFetch('/admin/all');

  useEffect(() => {
    if (data?.length) {
      setFilteredAdmins(data);
    }
  }, [data]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (data?.length) {
        setFilteredAdmins(data);
      }
    }
  }, [filterName]);

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(
        data || [],
        ['username', 'firstName', 'lastName', 'emailAddress'],
        {
          caseSensitive: false
        }
      );
      const result = searcher.search(filterName);
      setFilteredAdmins(result);
    } else {
      setFilteredAdmins(data || []);
    }
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelected(null);
  };

  const handleClick = (item) => {
    setSelected(item);
    setOpenDetails(true);
  };

  const handleDelete = async () => {
    setRequestLoading(true);

    setConfirm(false);
    setOpenDetails(false);

    const response = APIService.delete('/admin/delete', selected?.id);

    toast.promise(
      response,
      {
        loading: 'Deleting...',
        success: () => {
          setFilteredAdmins((prev) => prev?.filter((admin) => admin?.id !== selected?.id));
          mutate();
          setSelected(null);
          setRequestLoading(false);
          return `${selected?.username} has been deleted!`;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to  delete customer.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  return (
    <>
      {requestLoading && <LoadingBackdrop open={requestLoading} setOpen={setRequestLoading} />}

      {confirm && (
        <ConfirmModal
          open={confirm}
          setOpen={setConfirm}
          handleAction={handleDelete}
          action="DELETE"
        />
      )}
      {openCreateForm && (
        <CustomModal
          open={openCreateForm}
          setOpen={setOpenCreateForm}
          title="Create New Admin"
          modalSize="sm"
        >
          <CreateUserForm
            mutate={mutate}
            setOpenForm={setOpenCreateForm}
            userType="admin"
            setList={setFilteredAdmins}
          />
        </CustomModal>
      )}
      <StyledDrawer anchor="right" open={openDetails} onClose={handleCloseDetails}>
        <DrawerHeader>
          <IconButton onClick={handleCloseDetails}>
            <Iconify icon="fe:close" />
          </IconButton>
        </DrawerHeader>
        <AdminForm data={selected} mutate={mutate} setConfirm={setConfirm} />
      </StyledDrawer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 2, mx: 0, pr: 3 }}
      >
        <UserListToolbar
          filterName={filterName}
          onFilterName={(e) => setFilterName(e.target.value)}
        />
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => setOpenCreateForm(true)}
        >
          Create Admin
        </Button>
      </Stack>

      {filteredAdmins?.length ? (
        <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 5 }}>
          {filteredAdmins?.map((admin, index) => (
            <AdminCard key={index} data={admin} handleClick={handleClick} />
          ))}
        </List>
      ) : (
        <>
          {loading && <LinearProgress color="secondary" />}
          <Empty text="No Admin" />
        </>
      )}
    </>
  );
}

export default AdminList;
