import { useEffect, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
import toast from 'react-hot-toast';
import Card from '@mui/material/Card';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Empty from '../empty';
import { useSWRFetch } from '../../hooks';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { PackageCard, PackageDetailCard } from '../cards';
import { ConfirmModal, CustomModal, FormModal } from '../modal';
import { FilterForm, PackageForm } from '../forms';
import APIService from 'src/service';
import LoadingBackdrop from '../loading/Backdrop';
import Iconify from '../iconify';

const categories = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Website',
    value: 'website'
  },
  {
    label: 'Other Services',
    value: 'other services'
  },
  {
    label: 'Mobile App',
    value: 'mobile app'
  }
];

function PackageList() {
  const [requestLoading, setRequestLoading] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [category, setCategory] = useState('all');
  const [confirm, setConfirm] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const { data, loading, mutate } = useSWRFetch('package/all');

  useEffect(() => {
    if (data?.length) {
      setFilteredPackages(data);
    }
  }, [data]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (data?.length) {
        setFilteredPackages(data);
      }
    }
  }, [filterName]);

  useEffect(() => {
    if (category && data?.length) {
      if (category !== 'all') {
        setFilteredPackages(data?.filter((item) => item?.category === category));
      } else {
        setFilteredPackages(data);
      }
    }
  }, [category]);

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(data || [], ['name', 'category', 'price'], {
        caseSensitive: false
      });
      const result = searcher.search(filterName);
      setFilteredPackages(result);
    } else {
      setFilteredPackages(data || []);
    }
  };

  const handleAction = (action, item) => {
    setSelected(item);
    if (action === 'View') {
      setOpenDetails(true);
    } else if (action === 'Update') {
      setOpenUpdateForm(true);
    } else {
      setConfirm(true);
    }
  };

  const handleDelete = async () => {
    setRequestLoading(true);

    setConfirm(false);

    const response = APIService.delete('/admin/package', selected?.id);

    toast.promise(
      response,
      {
        loading: 'Deleting...',
        success: () => {
          setFilteredPackages((prev) => prev?.filter((item) => item?.id !== selected?.id));
          mutate();
          setSelected(null);
          setRequestLoading(false);
          return `${selected?.name} has been deleted!`;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to  delete customer.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  return (
    <>
      {confirm && (
        <ConfirmModal
          open={confirm}
          setOpen={setConfirm}
          handleAction={handleDelete}
          action={'DELETE'}
        />
      )}
      {requestLoading && <LoadingBackdrop open={requestLoading} setOpen={setRequestLoading} />}

      <CustomModal
        open={openDetails}
        setOpen={setOpenDetails}
        title={selected?.category?.toUpperCase()}
        modalSize={selected?.category === 'website' ? 'md' : 'sm'}
      >
        <PackageDetailCard data={selected} handleUpdate={() => setOpenUpdateForm(true)} />
      </CustomModal>

      {openUpdateForm && (
        <CustomModal
          open={openUpdateForm}
          setOpen={setOpenUpdateForm}
          title="Update Package"
          modalSize="sm"
        >
          <PackageForm
            selected={selected}
            loading={requestLoading}
            setLoading={setRequestLoading}
            setModal={setOpenUpdateForm}
            mutate={mutate}
          />
        </CustomModal>
      )}

      <Stack
        component={Card}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, pr: 3 }}
      >
        <UserListToolbar
          filterName={filterName}
          onFilterName={(e) => setFilterName(e.target.value)}
        />
        <FilterForm value={category} setValue={setCategory} options={categories} />
      </Stack>

      {filteredPackages?.length ? (
        <>
          {loading && <LinearProgress color="secondary" />}

          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {filteredPackages?.map((service, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <PackageCard data={service} handleAction={handleAction} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          {loading && <LinearProgress color="secondary" />}
          <Empty text="No package found" />
        </>
      )}
    </>
  );
}

export default PackageList;
