// material
import PropType from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const status = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'Terminated',
    value: 'terminated'
  }
];

ServiceForm.propTypes = {
  value: PropType.string.isRequired,
  setValue: PropType.func.isRequired
};

function ServiceForm({ value, setValue }) {
  return (
    <Box sx={{ width: '100%' }}>
      <TextField
        fullWidth
        select
        label="Status"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        SelectProps={{
          native: true
        }}
      >
        {status.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Box>
  );
}

export default ServiceForm;
