import PropType from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function FinalizeTransferForm({ value, setValue }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
        Enter the OTP sent to your business contact
      </Typography>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus
        id="otp"
        label="OTP"
        type="text"
        fullWidth
        variant="outlined"
      />
    </Box>
  );
}

export default FinalizeTransferForm;

FinalizeTransferForm.propTypes = {
  value: PropType.string.isRequired,
  setValue: PropType.func.isRequired
};
