import { useEffect, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Drawer from '@mui/material/Drawer';
import Empty from '../empty';
import { fToNow } from '../../utils/formatTime';
import { useSWRFetch } from '../../hooks';
import { colorVariant, formatCurrency } from 'src/utils';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { InvoiceCard } from '../cards';
import Iconify from '../iconify';
import Label from '../label';
import PreviewImage from '../preview';
import { FormModal } from '../modal';
import { InvoiceForm } from '../forms';
import APIService from 'src/service';
import toast from 'react-hot-toast';
import LoadingBackdrop from '../loading/Backdrop';
import { useLocation } from 'react-router-dom';

const drawerWidth = 460;

const StyledDateGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  height: '100% !important',
  //   minHeight: 350,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    fontWeight: 400
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  marginRight: 5,
  [theme.breakpoints.down('sm')]: {
    width: 20,
    height: 20
  }
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

const AdvanceToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton sx={{ color: 'text.primary' }} />
    <GridToolbarExport sx={{ color: 'text.primary' }} />
  </GridToolbarContainer>
);

function InvoiceList() {
  const { state } = useLocation();
  // const navigate = useNavigate();
  const [image, setImage] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('unpaid');
  const [filterName, setFilterName] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { data, loading, mutate } = useSWRFetch(
    `/admin/invoices?page=${pageIndex}&limit=${pageLimit}`
  );
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  useEffect(() => {
    if (data) {
      if (data?.docs?.length) {
        const mappedData = data?.docs?.map((item) => {
          const subPackage =
            item?.package?.category === 'website'
              ? item?.package?.subPackages?.filter((p) => p?._id === item?.subPackage)[0]
              : null;
          return {
            ...item,
            subPackage
          };
        });
        setFilteredInvoices(mappedData);
      }
    }
  }, [data]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (data?.docs?.length) {
        setFilteredInvoices(data?.docs);
      }
    }
  }, [filterName]);

  useEffect(() => {
    if (state?.id) {
      setSelected(state);
      setOpenDetails(true);
    }
  }, [state]);

  const columns = [
    {
      field: 'user',
      headerName: 'Customer',
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StyledAvatar>
            <Avatar alt={params?.value?.username} src={params?.value?.photoUrl} />
          </StyledAvatar>
          <StyledTypography variant="body1">{params?.value?.username}</StyledTypography>
        </Stack>
      )
    },
    {
      field: 'invoiceId',
      headerName: 'Invoice Id',
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <StyledTypography variant="body1" noWrap>
          #{params?.value}
        </StyledTypography>
      )
    },
    {
      field: 'amount',
      sortable: true,
      flex: 1,
      renderCell: (params) => <Typography>{formatCurrency(params?.value)}</Typography>
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      renderCell: (params) => (
        <StyledTypography variant="body1">{fToNow(params?.value)}</StyledTypography>
      )
    },
    {
      field: 'status',
      sortable: false,
      align: 'left',
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Label variant="ghost" color={colorVariant(params?.value)}>
          {params?.value}
        </Label>
      )
    },
    {
      field: '',
      headerName: 'Payment',
      sortable: false,
      align: 'left',
      flex: 1,
      renderCell: (params) =>
        params?.row?.paymentProof ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={() => setImage(params?.row?.paymentProof)}>
            Proof of payment
          </Button>
        ) : (
          <Label variant="ghost" color={colorVariant(params?.row?.status)}>
            {params?.row?.status === 'unpaid' ? 'Pending Payment' : 'Online payment'}
          </Label>
        )
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      headerAlign: 'right',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Iconify icon="ion:receipt" width={24} />}
          onClick={() => {
            setSelected(params.row);
            setOpenDetails(true);
          }}
          label="View"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Iconify icon="eva:edit-fill" width={24} />}
          onClick={() => {
            setSelected(params.row);
            setOpenDetails(false);
            setOpenUpdateForm(true);
          }}
          label="Update Status"
          showInMenu
        />
      ]
    }
  ];

  const handlePageChange = (value) => {
    if (data?.hasNextPage || data?.hasPrevPage) {
      setPageIndex(value + 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageLimit(value);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelected(null);
  };

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(data?.docs || [], ['invoiceId', 'amount', 'user.username'], {
        caseSensitive: false
      });
      const result = searcher.search(filterName);
      setFilteredInvoices(result);
    } else {
      setFilteredInvoices(data?.docs || []);
    }
  };

  const handleClose = () => {
    setImage('');
  };

  const handleInvoiceUpdate = async () => {
    setRequestLoading(true);

    const response = APIService.update('/admin/invoice', selected?.id, { status: selectedStatus });

    toast.promise(
      response,
      {
        loading: 'Updating...',
        success: (res) => {
          mutate();
          setOpenUpdateForm(false);
          setSelected(null);
          setRequestLoading(false);
          setSelectedStatus('unpaid');
          return `${res?.data?.invoiceId} has been updated!`;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to update invoice.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  return (
    <>
      {requestLoading && <LoadingBackdrop open={requestLoading} setOpen={setRequestLoading} />}
      {image && <PreviewImage image={image} close={handleClose} />}
      {openUpdateForm && (
        <FormModal
          open={openUpdateForm}
          setOpen={setOpenUpdateForm}
          title="Update Payment Status"
          handleAction={handleInvoiceUpdate}>
          <InvoiceForm value={selectedStatus} setValue={setSelectedStatus} />
        </FormModal>
      )}
      <UserListToolbar
        filterName={filterName}
        onFilterName={(e) => setFilterName(e.target.value)}
      />

      <StyledDrawer anchor="right" open={openDetails} onClose={handleCloseDetails}>
        <DrawerHeader>
          <IconButton onClick={handleCloseDetails}>
            <Iconify icon="fe:close" />
          </IconButton>
        </DrawerHeader>
        <InvoiceCard data={selected} handleUpdate={() => setOpenUpdateForm(true)} />
      </StyledDrawer>

      <StyledDateGrid
        rows={filteredInvoices}
        columns={columns}
        loading={loading}
        components={{
          Toolbar: AdvanceToolbar,
          NoRowsOverlay: () => <Empty text="No Invoice Found" />,
          LoadingOverlay: () => <LinearProgress color="secondary" />
        }}
        autoHeight
        disableColumnSelector={false}
        checkboxSelection={false}
        pagination
        paginationMode="server"
        page={pageIndex - 1}
        pageSize={pageLimit}
        rowsPerPageOptions={[10, 20, 30]}
        onPageSizeChange={handlePageSizeChange}
        rowCount={data?.totalDocs || 10}
        onPageChange={(value) => handlePageChange(value)}
      />
    </>
  );
}

export default InvoiceList;
