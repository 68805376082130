import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AOS from 'aos';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { useProfile } from './hooks';
import { setAuth, setProfile } from './store/reducer/auth';
import { setLoading } from './store/reducer/lifeCircle';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { socket } from './utils';
import { Toaster } from 'react-hot-toast';

// ----------------------------------------------------------------------

export default function App() {
  const { isAuth, profile } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.lifeCircle);
  const [mountedComponent, setMountedComponent] = useState(false);
  const { data, loggedOut, loading: dataLoading, mutate: profileMutate } = useProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    setMountedComponent(true);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      delay: 0,
      easing: 'ease-in-out',
      offset: 0
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  }, [mountedComponent]);

  useEffect(() => {
    socket.on('connect', (connect) => {
      console.log('socket-user', connect.id);
    });
    dispatch(setLoading(dataLoading));

    if (data) {
      dispatch(setProfile(data));
      dispatch(setAuth(true));
      dispatch(setLoading(false));
    }
    if (loggedOut) {
      dispatch(setAuth(false));
      dispatch(setProfile(null));
    }
    return () => socket.disconnect();
  }, [data, loggedOut, dataLoading]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <Toaster />
      <Router isAuth={isAuth} profile={profile} mutate={profileMutate} loading={loading} />
    </ThemeProvider>
  );
}
