import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Label from '../label';
import { colorVariant } from 'src/utils';
import { MoreMenu } from '../menu';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: 0
}));

const ColoredTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bolder',
  textTransform: 'capitalize'
}));

const options = [
  {
    text: 'View',
    icon: 'carbon:view-filled'
  },
  {
    text: 'Update',
    icon: 'eva:edit-fill'
  },
  {
    text: 'Delete',
    icon: 'fe:trash'
  }
];

function ServiceCard(props) {
  const { data, handleAction, isMobile } = props;

  const packageDetail =
    data?.package?.category === 'website'
      ? {
          ...data?.package,
          ...data?.subPackage,
          category: data?.package?.category,
          interval: data?.package?.interval,
          title: data?.package?.name
        }
      : data?.package;

  return (
    <StyledCard>
      <CardHeader
        avatar={
          <Avatar src={data?.user?.photoUrl} alt={data?.user?.username} aria-label="avatar" />
        }
        action={<MoreMenu options={options} handleClick={(params) => handleAction(params, data)} />}
        title={data?.user?.username}
        subheader={data?.user?.emailAddress}
        sx={{
          '& .MuiCardHeader-avatar': {
            flexGrow: isMobile ? 1 : 0
          },
          '& .MuiCardHeader-content': {
            display: isMobile ? 'none' : 'block'
          },
          px: 2,
          py: 2
        }}
      />
      <CardMedia
        component="img"
        height="150"
        image={packageDetail?.featuredImage}
        alt={packageDetail?.name}
        loading="lazy"
      />
      <CardContent
        sx={{
          px: 2,
          py: 2
        }}
      >
        <ColoredTypography variant="body2" color="text.secondary">
          {packageDetail?.name}
        </ColoredTypography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" color="text.secondary">
            {packageDetail?.category}
          </Typography>
          <Label variant="ghost" color={colorVariant(data?.status)}>
            {data?.status}
          </Label>
        </Stack>
      </CardContent>
    </StyledCard>
  );
}

export default ServiceCard;

ServiceCard.propTypes = {
  data: PropType.object,
  handleAction: PropType.func,
  isMobile: PropType.bool
};
