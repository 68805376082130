import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Spacer from '../spacer';
import Empty from '../empty';

const StyledCard = styled(Card)(({ theme }) => ({
  height: 200,
  backgroundColor: theme.palette.primary.main,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: 'center',
  backgroundImage: `url(/assets/images/card-pattern1.png)`,
  padding: 0
}));

const StyleCardContent = styled(CardContent)(({ theme }) => ({
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

const ColoredTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bolder',
  textTransform: 'capitalize'
}));

const ChipImage = styled('img')(({ theme }) => ({
  width: 48,
  height: 48,
  objectFit: 'contain'
}));

DebitCard.propTypes = {
  debitCard: PropType.object
};

function DebitCard(props) {
  const { debitCard } = props;
  return (
    <StyledCard variant="outlined">
      <StyleCardContent>
        {debitCard ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginTop: 2 }}
            >
              <ChipImage src="/assets/images/chip.png" alt="..." />
              <ColoredTypography>{debitCard?.card_type}</ColoredTypography>
            </Stack>
            <ColoredTypography variant="h4">
              <span>**** **** **** </span>
              {debitCard?.last4}
            </ColoredTypography>
            <Spacer flex />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <ColoredTypography>{debitCard?.account_name}</ColoredTypography>
              <ColoredTypography>{`${debitCard?.exp_month}/${debitCard?.exp_year}`}</ColoredTypography>
            </Stack>
          </>
        ) : (
          <Empty text="Nothing to display" />
        )}
      </StyleCardContent>
    </StyledCard>
  );
}

export default DebitCard;
