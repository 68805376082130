/* eslint-disable prettier/prettier */
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// component
import Iconify from '../../../components/iconify';
import APIService from 'src/service';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const result = await APIService.fetcher(`/admin/search?search=${keyword}`);
      if (result?.length) {
        setLoading(false);
        navigate('/dashboard/customers/detail', {
          state: { customer: result[0], page: 1 }
        });
      } else {
        setLoading(false);
        toast.error(`No record was found with the keyword: ${keyword}`);
      }
      setKeyword('');
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response?.data?.message ||
        error?.statusText ||
        'An error occurred while searching customer.'
      );
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search customer by name/phone/email…"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <LoadingButton
              variant="contained"
              disabled={loading}
              loading={loading}
              onClick={handleSearch}>
              Search
            </LoadingButton>
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
