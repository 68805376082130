import PropType from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast from 'react-hot-toast';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import APIService from 'src/service';
import { AmountInput, ChipInput } from './input';
// components

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  category: Yup.string().required('Category is required'),
  desc: Yup.string(),
  featuredImage: Yup.string().required('Featured Image is required'),
  price: Yup.string(),
  interval: Yup.string().required('Interval is required'),
  features: Yup.array(),
  subPackages: Yup.array(),
  negotiable: Yup.bool()
});

const categories = [
  {
    label: 'Website',
    value: 'website'
  },
  {
    label: 'Other Services',
    value: 'other services'
  }
];

const intervals = [
  {
    label: 'Monthly',
    value: 'monthly'
  },
  {
    label: 'Annually',
    value: 'annually'
  }
];

function PackageForm({ selected, loading, setLoading, setModal, mutate }) {
  const [feature, setFeature] = useState('');
  const [selectedChip, setSelectedChip] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: selected?.name || '',
      category: selected?.category || 'website',
      desc: selected?.desc || '',
      featuredImage: selected?.featuredImage || '',
      price: selected?.price || '',
      interval: selected?.interval || '',
      features: selected?.features || [],
      subPackages: selected?.subPackages || [],
      negotiable: selected?.negotiable || false
    },
    validationSchema: schema,
    onSubmit: async () => {
      setLoading(true);

      const price = values.price?.replace(/[^a-zA-Z0-9 ]/g, '');

      const payload = { ...values, price };
      const response = APIService.update('/admin/package', selected?.id, payload);

      toast.promise(
        response,
        {
          loading: 'Updating...',
          success: (res) => {
            mutate();
            setModal(false);
            setLoading(false);
            return `${res?.data?.name} has been updated!`;
          },
          error: (err) => {
            setLoading(false);
            return (
              err?.response?.data?.message ||
              err?.message ||
              'Something went wrong, while trying to update invoice.'
            );
          }
        },
        {
          error: {
            duration: 6000
          }
        }
      );
    }
  });

  const { errors, touched, values, handleSubmit, setFieldValue, getFieldProps } = formik;

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      if (selectedChip) {
        const features = values.features.map((item, i) =>
          selectedChip.index === i ? feature : item
        );
        setFieldValue('features', features);
        setFeature('');
        setSelectedChip(null);
        return;
      }
      setFieldValue('features', [...values.features, feature]);
      setFeature('');
    }
  };

  const handleSelected = (value, index) => {
    setFeature(value);
    setSelectedChip({
      value,
      index
    });
  };

  const handleChipDelete = (index) => {
    const features = [...values.features];
    features.splice(index, 1);
    setFieldValue('features', features);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <TextField
            fullWidth
            label="Name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            select
            fullWidth
            label="Category"
            {...getFieldProps('category')}
            error={Boolean(touched.category && errors.category)}
            helperText={touched.category && errors.category}
            SelectProps={{
              native: true
            }}
          >
            {categories.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="About package?"
            {...getFieldProps('desc')}
            multiline
            rows={4}
          />
          {values.category === 'other services' && (
            <TextField
              fullWidth
              label="price"
              {...getFieldProps('price')}
              error={Boolean(touched.price && errors.price)}
              helperText={touched.price && errors.price}
              InputProps={{
                inputComponent: AmountInput
              }}
            />
          )}
          <TextField
            select
            fullWidth
            label="interval"
            {...getFieldProps('interval')}
            error={Boolean(touched.interval && errors.interval)}
            helperText={touched.interval && errors.interval}
            SelectProps={{
              native: true
            }}
          >
            {intervals.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>

          {values.category === 'other services' && (
            <ChipInput
              chips={values.features}
              label="features"
              value={feature}
              selected={selectedChip}
              onChange={(e) => setFeature(e.target.value)}
              onKeyDown={handleKeyDown}
              onDelete={handleChipDelete}
              onSelect={handleSelected}
            />
          )}

          <LoadingButton fullWidth loading={loading} size="large" type="submit" variant="contained">
            Update
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default PackageForm;

PackageForm.propTypes = {
  selected: PropType.object.isRequired,
  loading: PropType.bool.isRequired,
  setLoading: PropType.func.isRequired,
  setModal: PropType.func.isRequired,
  mutate: PropType.func.isRequired
};
