import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ title, titleColor, dark, size, disabledLink, fontSize, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
        <Box sx={{ width: size, height: size }}>
          <img
            src={dark ? '/assets/icons/logo-white.svg' : '/assets/icons/logo.svg'}
            width="100%"
            alt="..."
          />
        </Box>
        {title && (
          <Typography
            component="h1"
            sx={{ color: titleColor, fontSize, marginLeft: 1, fontWeight: 'normal' }}>
            <span style={{ fontWeight: 900 }}>Web</span>OnTheGo
          </Typography>
        )}
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

Logo.displayName = 'logo';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  size: PropTypes.number,
  fontSize: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  dark: PropTypes.bool
};

Logo.defaultProps = {
  disabledLink: false,
  dark: false,
  size: 40,
  fontSize: 18
};

export default Logo;
