import { useEffect, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import LinearProgress from '@mui/material/LinearProgress';
import Empty from '../empty';
import { fDate } from '../../utils/formatTime';
import { useSWRFetch } from '../../hooks';
import Iconify from '../iconify';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../modal';
import APIService from 'src/service';
import toast from 'react-hot-toast';
import LoadingBackdrop from '../loading/Backdrop';

const StyledDateGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  height: '100% !important',
  //   minHeight: 350,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    fontWeight: 400
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12
  }
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  }
  //   '@keyframes ripple': {
  //     '0%': {
  //       transform: 'scale(.8)',
  //       opacity: 1
  //     },
  //     '100%': {
  //       transform: 'scale(2.4)',
  //       opacity: 0
  //     }
  //   }
}));

function CustomerList() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const currentPage = query.get('page');

  const [requestLoading, setRequestLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [selected, setSelected] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [filterName, setFilterName] = useState('');
  const { data, loading, mutate } = useSWRFetch(
    `/admin/customers?page=${pageIndex}&limit=${pageLimit}`
  );
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  useEffect(() => {
    if (data) {
      if (data?.docs?.length) {
        setFilteredCustomers(data?.docs);
      }
    }
  }, [data]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (data?.docs?.length) {
        setFilteredCustomers(data?.docs);
      }
    }
  }, [filterName]);

  const columns = [
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: params?.row?.active ? '#8bc34a' : '#ff5252',
                color: params?.row?.active ? '#8bc34a' : '#ff5252'
              }
            }}
          >
            <Avatar alt={params?.row?.username} src={params?.row?.photoUrl} />
          </StyledBadge>
          <StyledTypography variant="body1">{params?.value}</StyledTypography>
        </Stack>
      )
    },
    {
      field: 'emailAddress',
      headerName: 'Email',
      maxWidth: 250,
      flex: 1
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone',
      sortable: false,
      maxWidth: 250,
      flex: 1
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      maxWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <StyledTypography variant="body1">{fDate(params?.value)}</StyledTypography>
      )
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      headerAlign: 'right',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Iconify icon="fe:user" width={24} />}
          onClick={() =>
            navigate('/dashboard/customers/detail', {
              state: { customer: params?.row, page: pageIndex }
            })
          }
          label="View"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Iconify icon="fe:trash" width={24} />}
          onClick={() => {
            setSelected(params?.row);
            setConfirm(true);
          }}
          label="Delete"
          color="error"
          showInMenu
        />
      ]
    }
  ];

  const handlePageChange = (value) => {
    if (data?.hasNextPage || data?.hasPrevPage) {
      setPageIndex(value + 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageLimit(value);
  };

  const handleDelete = async () => {
    setRequestLoading(true);

    setConfirm(false);

    const response = APIService.delete('/admin/customer', selected?.id);

    toast.promise(
      response,
      {
        loading: 'Deleting...',
        success: () => {
          setFilteredCustomers((prev) => prev?.filter((customer) => customer?.id !== selected?.id));
          mutate();
          setSelected(null);
          setRequestLoading(false);
          return `${selected?.username} has been deleted!`;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to  delete customer.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(
        data?.docs || [],
        ['username', 'emailAddress', 'phoneNumber'],
        {
          caseSensitive: false
        }
      );
      const result = searcher.search(filterName);
      setFilteredCustomers(result);
    } else {
      setFilteredCustomers(data?.docs || []);
    }
  };

  return (
    <>
      {confirm && (
        <ConfirmModal
          open={confirm}
          setOpen={setConfirm}
          handleAction={handleDelete}
          action="DELETE"
        />
      )}
      {requestLoading && <LoadingBackdrop open={requestLoading} setOpen={setRequestLoading} />}
      <UserListToolbar
        filterName={filterName}
        onFilterName={(e) => setFilterName(e.target.value)}
      />

      <StyledDateGrid
        rows={filteredCustomers}
        columns={columns}
        loading={loading}
        components={{
          // Toolbar: () => <AdvanceToolbar setFilteredCustomers={setFilteredCustomers} />,
          NoRowsOverlay: () => <Empty text="No Customers Found" />,
          LoadingOverlay: () => <LinearProgress color="secondary" />
        }}
        autoHeight
        disableColumnSelector={false}
        checkboxSelection={false}
        pagination
        paginationMode="server"
        page={pageIndex - 1}
        pageSize={pageLimit}
        rowsPerPageOptions={[10, 20, 30]}
        onPageSizeChange={handlePageSizeChange}
        rowCount={data?.totalDocs || 10}
        onPageChange={(value) => handlePageChange(value)}
      />
    </>
  );
}

export default CustomerList;
