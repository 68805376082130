import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import SvgColor from '../svg-color';

const Empty = ({ text }) => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    <SvgColor src={`/assets/icons/empty.svg`} sx={{ width: 100, height: 100, my: 2 }} />
    {text}
  </Stack>
);

export default Empty;

Empty.propTypes = {
  text: PropTypes.string.isRequired
};
