import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardHeader } from '@mui/material';
import { MoreMenu } from '../menu';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  minHeight: 200,
  borderRadius: 16,
  cursor: 'pointer',
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.02)'
  }
}));

PackageCard.propTypes = {
  data: PropType.object
};

const options = [
  {
    text: 'View',
    icon: 'carbon:view-filled'
  },
  {
    text: 'Update',
    icon: 'eva:edit-fill'
  },
  {
    text: 'Delete',
    icon: 'fe:trash'
  }
];

function PackageCard(props) {
  const { data, handleAction } = props;

  return (
    <StyledCard elevation={1}>
      <CardHeader
        action={<MoreMenu options={options} handleClick={(params) => handleAction(params, data)} />}
        title={
          <Typography variant="h5" lineHeight={1.2} textTransform="capitalize" gutterBottom>
            {data?.name}
          </Typography>
        }
        subheader={data?.category}
        sx={{ mb: 2 }}
      />
      <CardMedia src={data?.featuredImage} alt="..." component="img" height="120" />
      <CardContent sx={{ textAlign: 'start' }}>
        <Typography variant="caption" color="text.secondary">
          {data?.desc}
        </Typography>
      </CardContent>
    </StyledCard>
  );
}

export default PackageCard;

PackageCard.propTypes = {
  data: PropType.object,
  handleAction: PropType.func
};
