/**
 *
 * @param {Number} value
 * @param {Sting} currency
 * @returns
 */

const formatCurrency = (value, currency = '₦') =>
  currency +
  parseInt(value || 0)
    .toFixed(!value ? 2 : 0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');

export default formatCurrency;
