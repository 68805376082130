import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomDialogTitle from './CustomDialogTitle';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  root: {
    width: '100%'
  },
  '& .MuiDialogContent-root': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

FormModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired
};

export default function FormModal({ children, open, setOpen, title, handleAction }) {
  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <BootstrapDialog
      sx={{ '& .MuiDialog-paper': { width: '100%' } }}
      open={open}
      onClose={handleClose}
      maxWidth="sm">
      <CustomDialogTitle onClose={handleClose}>{title}</CustomDialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: 'text.secondary' }}>
          Cancel
        </Button>
        <Button onClick={handleAction} sx={{ color: 'text.primary' }}>
          Submit
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
