import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import useResponsive from '../../hooks/useResponsive';
// sections
import { LoginForm } from '../../sections/auth/login';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 780,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.primary.dark
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login(props) {
  const { mutate } = props;
  const { state } = useLocation();
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Webonthego </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <img src="/assets/images/banner1.png" alt="login" />
            <Typography variant="h2" fontWeight="bold" sx={{ px: 5, color: 'white' }}>
              Build. Deploy. Scale.
            </Typography>
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Hey Admin, sign in to continue.
            </Typography>

            <LoginForm mutate={mutate} state={state} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
