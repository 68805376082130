import { Helmet } from 'react-helmet-async';
// import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
// import Iconify from '../../components/iconify';
// sections
import {
  // AppTasks,
  AppNewsUpdate,
  // AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  // AppTrafficBySite,
  AppWidgetSummary
  // AppCurrentSubject,
  // AppConversionRates
} from '../../sections/@dashboard/app';
import { useSWRFetch } from 'src/hooks';
import { useEffect, useState } from 'react';
import { formatCurrency } from 'src/utils';
import Page from 'src/components/page';

// ----------------------------------------------------------------------

export default function DashboardAppPage(props) {
  const { profile } = props;
  const { data, loading } = useSWRFetch('/analysis');
  const { data: invoices } = useSWRFetch('/admin/invoice-by-date?date=recent');

  const [analysis, setAnalysis] = useState({
    totalIncome: [{ total: 0 }],
    totalCustomers: 0,
    totalServices: 0,
    totalInvoices: 0,
    totalPaidInvoices: 0,
    totalUnPaidInvoices: 0,
    totalPendingInvoices: 0,
    totalUnresolvedTickets: 0,
    customerByDevices: []
  });

  const theme = useTheme();

  useEffect(() => {
    if (data) {
      setAnalysis({
        ...data,
        customerByDevices: data?.customerByDevices?.map((item) => ({
          label: item._id || 'web',
          value: item.count || 0
        }))
      });
    }
  }, [data]);

  // useEffect(() => {
  //   console.log('invoices', invoices);
  // }, [invoices]);

  return (
    <Page title="Dashboard" sx={{ height: '100%' }}>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back @{profile?.username}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Revenue"
              total={analysis.totalIncome[0].total}
              icon={'fa6-solid:money-bill'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Registered Customers"
              total={analysis.totalCustomers}
              color="info"
              icon={'gridicons:multiple-users'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Services"
              total={analysis.totalServices}
              color="warning"
              icon={'ic:round-design-services'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Pending Invoices"
              total={analysis.totalPendingInvoices}
              color="error"
              icon={'fa6-solid:file-invoice-dollar'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003'
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
                }
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Users By Devices"
              chartData={analysis.customerByDevices}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.secondary.main,
                theme.palette.warning.main
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 }
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] }
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          <Grid item xs={12}>
            <AppNewsUpdate
              title="Recent Invoice"
              list={invoices?.map((item, index) => ({
                ...item,
                title: `#${item?.invoiceId} ${formatCurrency(item?.amount)}`,
                description: `${item?.service?.package?.name} (${item?.service?.package?.category})`,
                image: item?.user?.photoUrl,
                postedAt: new Date(item?.createdAt)
              }))}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />
                }
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
