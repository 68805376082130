import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
// material
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
// component
import APIService from '../../service';
import Iconify from '../iconify';
import { Box } from '@mui/system';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const usernameExp = /^[a-z0-9]+$/i;

const sex = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  }
];

const roles = [
  {
    label: 'Analyst',
    value: 'analyst'
  },
  {
    label: 'Editor',
    value: 'editor'
  },
  {
    label: 'Manager',
    value: 'manager'
  }
];

const types = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Super Admin',
    value: 'superadmin'
  }
];

const schema = Yup.object().shape({
  username: Yup.string()
    .matches(usernameExp, 'Enter a valid username without any special character')
    .trim()
    .lowercase()
    .min(4)
    .max(20)
    .required('Username is required'),
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
  emailAddress: Yup.string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Enter a valid phone number')
    .required('Phone number is required'),
  gender: Yup.string().required('Gender is required'),
  password: Yup.string().required('Password is required'),
  role: Yup.string().required('Role is required'),
  type: Yup.string().required('User Type is required')
});

function CreateUserForm({ mutate, userType, setOpenForm, setList }) {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode] = useState('+234');
  const [isValidUsername, setIsValidUsername] = useState(null);

  const formik = useFormik({
    initialValues: {
      username: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      gender: 'male',
      role: 'analyst',
      type: 'admin',
      password: ''
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);

      let phone;

      if (!`${values.phoneNumber}`.includes('+234')) {
        phone = `+234${values.phoneNumber}`;
      } else {
        phone = values.phoneNumber;
      }

      const payload = { ...values, phoneNumber: phone };
      payload.privilege = {
        type: payload.type,
        role: payload.role,
        claim: payload.role.includes('analyst') ? 'readonly' : 'read/write'
      };

      const response = APIService.post('admin/create', payload);

      toast.promise(response, {
        loading: 'Loading',
        success: (res) => {
          setLoading(false);
          setList((prev) => [res.data, ...prev]);
          mutate();
          setOpenForm(false);
          return `${values.username} has been created!`;
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        }
      });
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleUsernameChange = async (e) => {
    try {
      setIsValidUsername(null);

      const hasError = Boolean(touched.username && errors.username);

      if (!hasError) {
        const response = await APIService.post('/admin/validate-username', {
          username: e.target.value
        });
        if (response.data?.status) {
          setIsValidUsername(true);
        }
      }
    } catch (err) {
      setIsValidUsername(false);
      toast.error(
        err?.response?.data?.message || err?.message || 'Something went wrong, try again.'
      );
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form style={{ width: '100%' }} autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack sx={{ overflow: 'auto', mb: 8 }} spacing={3}>
          <TextField
            fullWidth
            id="outlined-required"
            autoComplete="username"
            label="Username"
            {...getFieldProps('username')}
            onBlur={handleUsernameChange}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi:at" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isValidUsername === false ? (
                    <Iconify icon={'mdi:close-thick'} sx={{ color: 'red' }} />
                  ) : isValidUsername === true ? (
                    <Iconify icon={'mdi:check-bold'} sx={{ color: 'green' }} />
                  ) : null}
                </InputAdornment>
              )
            }}
            onKeyPress={(e) => {
              // eslint-disable-next-line prefer-regex-literals
              const validReg = new RegExp(/^[a-z0-9]+$/i).test(e.key);
              if (!validReg) {
                e.preventDefault();
              }
            }}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="emailAddress"
            type="email"
            label="Email address"
            {...getFieldProps('emailAddress')}
            error={Boolean(touched.emailAddress && errors.emailAddress)}
            helperText={touched.emailAddress && errors.emailAddress}
          />
          <TextField
            fullWidth
            label="Phone Number"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{countryCode}</Typography>
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            select
            label="Gender"
            {...getFieldProps('gender')}
            SelectProps={{
              native: true
            }}
            error={Boolean(touched.gender && errors.gender)}
            helperText={touched.gender && errors.gender}
          >
            {sex.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Role"
            {...getFieldProps('role')}
            SelectProps={{
              native: true
            }}
            error={Boolean(touched.role && errors.role)}
            helperText={touched.role && errors.role}
          >
            {roles.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="User Type"
            {...getFieldProps('type')}
            SelectProps={{
              native: true
            }}
            error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}
          >
            {types.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <Box
          sx={{
            bgcolor: 'background.default',
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            width: '95%',
            margin: 'auto',
            py: 2,
            zIndex: 1
          }}
        >
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            Create
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default CreateUserForm;

CreateUserForm.propTypes = {
  userType: PropTypes.string,
  mutate: PropTypes.func.isRequired,
  setOpenForm: PropTypes.func.isRequired,
  setList: PropTypes.func.isRequired
};
