// material
import PropType from 'prop-types';
import TextField from '@mui/material/TextField';

FilterForm.propTypes = {
  value: PropType.string.isRequired,
  setValue: PropType.func.isRequired,
  label: PropType.string,
  options: PropType.arrayOf(PropType.object)
};

FilterForm.defaultProps = {
  label: 'Status'
};

function FilterForm({ value, setValue, label, options }) {
  return (
    <TextField
      select
      label={label}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      SelectProps={{
        native: true
      }}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
}

export default FilterForm;
