import { useEffect, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Empty from '../empty';
import { fDate } from '../../utils/formatTime';
import { useSWRFetch } from '../../hooks';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import Iconify from '../iconify';
import APIService from 'src/service';
import toast from 'react-hot-toast';
import LoadingBackdrop from '../loading/Backdrop';
import { ConfirmModal } from '../modal';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  height: '100% !important',
  //   minHeight: 350,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    fontWeight: 400
  }
}));

const AdvanceToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton sx={{ color: 'text.primary' }} />
    <GridToolbarExport sx={{ color: 'text.primary' }} />
  </GridToolbarContainer>
);

function SubscriberList() {
  const [requestLoading, setRequestLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [confirm, setConfirm] = useState(false);
  const { data, loading, mutate } = useSWRFetch('/admin/contacts');
  const [filteredContacts, setFilteredContacts] = useState([]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      maxWidth: 250,
      flex: 1
    },
    {
      field: 'emailAddress',
      headerName: 'Email',
      maxWidth: 250,
      flex: 1
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone',
      sortable: false,
      maxWidth: 250,
      flex: 1
    },
    {
      field: 'type',
      headerName: 'type',
      sortable: false,
      maxWidth: 150,
      flex: 1
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      maxWidth: 120,
      flex: 1,
      renderCell: (params) => <Typography variant="body1">{fDate(params?.value)}</Typography>
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      headerAlign: 'right',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Iconify icon="material-symbols:call" width={24} />}
          onClick={() => {
            window.location = `tel:${params.row.phoneNumber}`;
          }}
          label="Call Contact"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Iconify icon="ion:mail-outline" width={24} />}
          onClick={() => {
            window.location = `mailto:${params.row.emailAddress}`;
          }}
          label="Send Mail"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Iconify icon="fe:trash" width={24} />}
          onClick={() => {
            setSelected(params?.row);
            setConfirm(true);
          }}
          label="Delete"
          showInMenu
        />
      ]
    }
  ];

  useEffect(() => {
    if (data?.length) {
      setFilteredContacts(data);
    }
  }, [data]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (data?.length) {
        setFilteredContacts(data);
      }
    }
  }, [filterName]);

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(data || [], ['name', 'emailAddress', 'phoneNumber'], {
        caseSensitive: false
      });
      const result = searcher.search(filterName);
      setFilteredContacts(result);
    } else {
      setFilteredContacts(data || []);
    }
  };

  const handleDelete = async () => {
    setRequestLoading(true);

    setConfirm(false);

    const response = APIService.delete('/admin/contact', selected?.id);

    toast.promise(
      response,
      {
        loading: 'Deleting...',
        success: () => {
          setFilteredContacts((prev) => prev?.filter((contact) => contact?.id !== selected?.id));
          mutate();
          setSelected(null);
          setRequestLoading(false);
          return `${selected?.name} has been deleted!`;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to  delete contact.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  return (
    <>
      {requestLoading && <LoadingBackdrop open={requestLoading} setOpen={setRequestLoading} />}
      {confirm && (
        <ConfirmModal
          open={confirm}
          setOpen={setConfirm}
          handleAction={handleDelete}
          action="DELETE"
        />
      )}
      <UserListToolbar
        filterName={filterName}
        onFilterName={(e) => setFilterName(e.target.value)}
      />
      <StyledDataGrid
        rows={filteredContacts}
        columns={columns}
        loading={loading}
        components={{
          Toolbar: AdvanceToolbar,
          NoRowsOverlay: () => <Empty text="No Invoice Found" />,
          LoadingOverlay: () => <LinearProgress color="secondary" />
        }}
        autoHeight
        disableColumnSelector={false}
        checkboxSelection={false}
      />
    </>
  );
}

export default SubscriberList;
