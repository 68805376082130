import PropType from 'prop-types';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { getPercentage, formatCurrency, colorVariant } from '../../utils';
import ProfileCard from './ProfileCard';
import Label from '../label';
import PreviewImage from '../preview';

const StyledCard = styled(Card)(({ theme }) => ({
  height: 'auto',
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)'
  }
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'text.primary'
}));

const Item = ({ keyName, value, bold, fontSize = 14 }) => (
  <ListItem
    divider
    secondaryAction={
      <StyledTypography sx={{ fontSize, fontWeight: bold ? 'bolder' : 'normal' }}>
        {value}
      </StyledTypography>
    }
  >
    <ListItemText
      primary={
        <StyledTypography sx={{ fontSize, fontWeight: bold ? 'bolder' : 'normal' }}>
          {keyName}
        </StyledTypography>
      }
    />
  </ListItem>
);

PreviewInvoiceCard.propTypes = {
  data: PropType.object,
  loading: PropType.bool,
  handleUpdate: PropType.func
};

export default function PreviewInvoiceCard(props) {
  const { data, loading, handleUpdate } = props;
  const [image, setImage] = useState('');

  const packageDetail =
    data?.service?.package?.category === 'website'
      ? {
          ...data?.subPackage,
          category: data?.service?.package?.category,
          interval: data?.service?.package.interval,
          title: data?.service?.package.name,
          amount: data?.amount
        }
      : { ...data?.service?.package, amount: data?.amount };

  const amount = parseInt(packageDetail?.amount);
  const percentAmount = getPercentage(
    parseInt(process.env.REACT_APP_VAT_PERCENT),
    packageDetail?.price
  );
  const subTotal = amount - percentAmount;
  const total = amount;

  const handleClose = () => {
    setImage('');
  };

  return (
    <Box sx={{ overflow: 'auto' }}>
      {image && <PreviewImage image={image} close={handleClose} />}
      <ProfileCard profile={data?.user} />
      <StyledCard sx={{ my: 5 }}>
        <StyledCardContent>
          <Box sx={{ flexGrow: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ p: 2, bgcolor: 'rgb(255 255 255 / 10%)', mb: 3 }}
            >
              <div>
                <StyledTypography variant="caption" textTransform="capitalize">
                  {packageDetail?.category}
                </StyledTypography>
                <StyledTypography variant="h6" textTransform="capitalize">
                  {packageDetail?.name} /{' '}
                  <span style={{ fontSize: 13, fontWeight: 'lighter' }}>
                    {packageDetail?.interval}
                  </span>
                </StyledTypography>
              </div>
              <StyledTypography variant="h4">
                {formatCurrency(packageDetail?.price)}
              </StyledTypography>
            </Stack>
            <List sx={{ px: 0, mt: 2 }}>
              <Item
                keyName="Invoice Status"
                value={
                  <Label variant="ghost" color={colorVariant(data?.status)}>
                    {data?.status}
                  </Label>
                }
              />
              <Item keyName="InvoiceId" value={`#${data?.invoiceId}`} />
              {data?.paymentProof && (
                <Item
                  keyName="Proof of payment"
                  value={
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => setImage(data?.paymentProof)}
                    >
                      Preview
                    </Button>
                  }
                />
              )}
              <Item keyName="Subtotal" value={formatCurrency(subTotal)} />
              <Item
                keyName={`VAT (${process.env.REACT_APP_VAT_PERCENT}%)`}
                value={formatCurrency(percentAmount)}
              />
              <Item keyName="Total" value={formatCurrency(total)} fontSize={16} bold />
            </List>
          </Box>
          <Box sx={{ mx: 2, mt: 3 }}>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              loading={loading}
              onClick={handleUpdate}
            >
              Update Payment Status
            </LoadingButton>
          </Box>
        </StyledCardContent>
      </StyledCard>
    </Box>
  );
}
