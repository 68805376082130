import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import APIService from './service';
import { store } from './store';

// @Styles
import 'aos/dist/aos.css';
import 'simplebar/src/simplebar.css';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <SWRConfig
          value={{
            // refreshInterval: 3000,
            revalidateOnFocus: true,
            revalidateOnReconnect: true,
            fetcher: (url) => APIService.fetcher(url)
          }}>
          <App />
        </SWRConfig>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
