import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Label from '../label';
import { colorVariant } from 'src/utils';
import { MoreMenu } from '../menu';
import { fToNow } from 'src/utils/formatTime';
import Iconify from '../iconify';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: 0
}));

function SupportCard({ data, loading, handleAction }) {
  return (
    <StyledCard>
      <CardHeader
        action={<Typography variant="body1">{fToNow(data?.createdAt)}</Typography>}
        title={
          <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
            {data?.subject}
          </Typography>
        }
        subheader={
          <Label variant="ghost" color={colorVariant(data?.status)}>
            {data?.status}
          </Label>
        }
      />
      <CardContent sx={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}>
        <Typography variant="body1" color="text.secondary">
          {data?.message}
        </Typography>
      </CardContent>
      {data?.status === 'pending' && (
        <CardActions sx={{ padding: 3 }}>
          <Button
            variant="contained"
            color="success"
            sx={{ color: 'white' }}
            endIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}
            onClick={() => handleAction('resolved')}
            disabled={loading}
          >
            Mark as Resolved
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ color: 'white' }}
            endIcon={<Iconify icon="eva:close-circle-fill" />}
            onClick={() => handleAction('closed')}
            disabled={loading}
          >
            Mark as Closed
          </Button>
        </CardActions>
      )}
    </StyledCard>
  );
}

export default SupportCard;

SupportCard.propTypes = {
  data: PropType.object,
  loading: PropType.bool,
  handleAction: PropType.func
};
