import PropTypes from 'prop-types';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Page from 'src/components/page';
import { AvatarForm, ProfileForm } from 'src/components/forms';
import { AdminList, PreferenceList } from 'src/components/list';
import { TabPanel } from 'src/components/tab';

const ColoredTypography = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === 'light' ? theme.palette.primary.darker : theme.palette.primary.lighter
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const tabComponent = (tab, profile, mutate, matches) => {
  switch (tab) {
    case 'Profile':
      return (
        <Stack spacing={2}>
          <AvatarForm profile={profile} mutate={mutate} />
          <ProfileForm profile={profile} mutate={mutate} matches={matches} />
        </Stack>
      );
    case 'Administrators':
      return <AdminList matches={matches} />;
    case 'Preference':
      return <PreferenceList matches={matches} />;
    default:
      return null;
  }
};

const tabs = ['Profile', 'Administrators', 'Preference'];

function Settings(props) {
  const { profile, mutate } = props;

  const [tab, setTab] = useState(0);
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <Page title="Settings" sx={{ height: '100%' }}>
      <Container maxWidth="xl">
        <ColoredTypography variant="h4" sx={{ mb: 5 }}>
          Settings
        </ColoredTypography>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper',
            borderRadius: 1
          }}>
          <Tabs value={tab} onChange={handleChange} aria-label="tab">
            {tabs.map((item, index) => (
              <Tab key={index} label={item} {...a11yProps(0)} />
            ))}
          </Tabs>
        </Box>
        {tabs?.map((item, index) => (
          <TabPanel key={item} value={tab} index={index}>
            {tabComponent(item, profile, mutate, matches)}
          </TabPanel>
        ))}
      </Container>
    </Page>
  );
}

export default Settings;

Settings.propTypes = {
  profile: PropTypes.object,
  mutate: PropTypes.func
};
