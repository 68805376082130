import PropType from 'prop-types';
import { useState } from 'react';
import { capitalCase } from 'change-case';
import { styled } from '@mui/material/styles';
import toast, { Toaster } from 'react-hot-toast';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import APIService from '../../service';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  padding: 0
}));

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2)
}));

const BackgroundBox = styled(Box)(() => ({
  height: 100,
  backgroundImage: "url('/assets/images/card-pattern6.png')",
  backgroundRepeat: 'repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}));

const ColoredTypography = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === 'light' ? theme.palette.primary.darker : theme.palette.primary.lighter,
  fontWeight: 'bolder',
  textTransform: 'capitalize'
}));

const HiddenInput = styled('input')(({ theme }) => ({
  display: 'none'
}));

AvatarForm.propTypes = {
  profile: PropType.object,
  mutate: PropType.func
};

function AvatarForm(props) {
  const { profile, mutate } = props;
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(0);
  const [selectedFile, setSelectedFile] = useState(profile?.photoUrl);

  const handleUpload = async (e) => {
    try {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = (e) => {
        setSelectedFile(reader.result);
      };

      const formData = new FormData();
      formData.append('image', file);

      const uploaded = await APIService.post('/media/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (evt) => {
          setProgress(Math.round((100 * evt.loaded) / evt.total));
        }
      });

      if (uploaded.status === 200) {
        const response = APIService.update('/admin', 'profile', { photoUrl: uploaded.data?.url });
        toast.promise(response, {
          loading: 'Updating profile photo...',
          success: () => {
            setLoading(false);
            mutate();
            return 'Profile Picture Updated Successfully!';
          },
          error: (err) => {
            setLoading(false);
            return (
              err?.response?.data?.message || err?.message || 'Something went wrong, try again.'
            );
          }
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          'something went wrong while uploading photo'
      );
    }
  };

  return (
    <StyledCard variant="outlined">
      <CardContent sx={{ padding: 0, paddingBottom: '0px !important' }}>
        <BackgroundBox />
        <LinearProgress variant="determinate" value={progress} color="success" />
        <ContentBox>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>
                <HiddenInput
                  accept="image/*"
                  id="profile-image-file"
                  type="file"
                  onChange={handleUpload}
                />
                <label htmlFor="profile-image-file">
                  <Avatar
                    src={selectedFile}
                    alt={capitalCase(profile?.username)}
                    sx={{ width: 50, height: 50 }}
                    imgProps={{
                      crossOrigin: 'anonymous'
                    }}
                  />
                </label>
              </div>
              <ColoredTypography variant="h4" sx={{ marginLeft: 1 }}>
                {profile?.username}
              </ColoredTypography>
            </Box>
            <label htmlFor="profile-image-file">
              <Button variant="contained" component="span">
                Change Profile Photo
              </Button>
            </label>
          </Stack>
        </ContentBox>
      </CardContent>
      <Toaster />
    </StyledCard>
  );
}

export default AvatarForm;
