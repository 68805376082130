import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { colorVariant, formatCurrency } from '../../utils';
import Spacer from '../spacer';
import Iconify from '../iconify';
import Label from '../label';
import ProfileCard from './ProfileCard';
import { fDate } from 'src/utils/formatTime';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)'
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'text.primary'
}));

const Item = ({ keyName, value, bold, fontSize = 14 }) => (
  <ListItem
    sx={{ px: 0 }}
    divider
    secondaryAction={
      <StyledTypography sx={{ fontSize, fontWeight: bold ? 'bolder' : 'normal' }}>
        {value}
      </StyledTypography>
    }
  >
    <ListItemText
      primary={
        <StyledTypography sx={{ fontSize, fontWeight: bold ? 'bolder' : 'normal' }}>
          {keyName}
        </StyledTypography>
      }
    />
  </ListItem>
);

function ServiceDetailCard(props) {
  const { data } = props;
  const packageDetail =
    data?.package?.category === 'website'
      ? {
          ...data?.subPackage,
          category: data?.package?.category,
          interval: data?.package?.interval,
          title: data?.package?.name
        }
      : data?.package;

  return (
    <Box sx={{ p: 3, overflow: 'auto' }}>
      <ProfileCard profile={data?.user} />
      <List
        sx={{ mt: 3 }}
        subheader={
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>Business Information</Typography>
            <Label variant="ghost" color={colorVariant(data?.status)}>
              {data?.status}
            </Label>
          </Stack>
        }
      >
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={
              <Typography variant="h4">
                {`${data?.businessName}`} <span style={{ fontSize: 14 }}>{data?.rcNumber}</span>
              </Typography>
            }
            secondary="business Name"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{data?.businessDesc}</Typography>}
            secondary="business Description"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{data?.businessType}</Typography>}
            secondary="business Type"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{data?.role}</Typography>}
            secondary="Position"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{`${data?.address} ${data?.country}`}</Typography>}
            secondary="business Address"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        {data?.domain ? (
          <ListItem divider sx={{ p: 0 }}>
            <ListItemText
              primary={<Typography variant="body1">{data?.domain}</Typography>}
              secondary="Website Domain Name"
              secondaryTypographyProps={{ color: 'text.disabled' }}
            />
          </ListItem>
        ) : null}
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{fDate(data?.createdAt)}</Typography>}
            secondary="Created Date"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
      </List>

      <Spacer size={4} />

      <StyledCard>
        <CardHeader title={<Typography>Package</Typography>} sx={{ px: 0, mb: 1 }} />
        <CardContent sx={{ textAlign: 'start', p: 0 }}>
          <Typography variant="body1">{packageDetail?.category}</Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography variant="h6" textTransform="capitalize">
              {packageDetail?.name}
            </Typography>
          </Stack>
          <Box sx={{ py: 1 }}>
            <Typography variant="subtitle2">
              {formatCurrency(packageDetail?.price)} /{' '}
              <span style={{ fontSize: 14 }}>{packageDetail?.interval}</span>
            </Typography>
          </Box>
          <List dense>
            {packageDetail?.features?.map((item, index) => (
              <ListItem sx={{ py: 0 }} key={index}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <Iconify icon="carbon:checkmark-filled" sx={{ color: '#4ed027' }} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </StyledCard>

      {data?.document && (
        <List sx={{ mt: 2 }} subheader={<Typography>Documents</Typography>}>
          {data?.document?.aboutUsDoc && (
            <Item
              keyName="Company Profile"
              value={
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={<Iconify icon="eva:download-fill" />}
                  component={Link}
                  href={data?.document?.aboutUsDoc}
                  download
                  target="_blank"
                >
                  Download
                </Button>
              }
            />
          )}
          {data?.document?.businessLogo && (
            <Item
              keyName="Brand Logo"
              value={
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={<Iconify icon="eva:download-fill" />}
                  component={Link}
                  href={data?.document?.businessLogo}
                  download
                  target="_blank"
                >
                  Download
                </Button>
              }
            />
          )}
        </List>
      )}
    </Box>
  );
}

export default ServiceDetailCard;

ServiceDetailCard.propTypes = {
  data: PropType.object
};
