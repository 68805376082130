import PropType from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Iconify from '../iconify';

function AdminCard({ data, handleClick }) {
  return (
    <ListItem
      sx={{ my: 1, bgcolor: 'background.default', borderRadius: 2 }}
      onClick={() => handleClick(data)}
      secondaryAction={
        <IconButton edge="end" aria-label="delete">
          <Iconify icon="eva:chevron-right-fill" />
        </IconButton>
      }>
      <ListItemAvatar>
        <Avatar alt={data?.username} src={data?.photoUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={!data?.fullName?.includes('undefined') ? data?.fullName : data?.username}
        secondary={data?.emailAddress}
      />
    </ListItem>
  );
}

export default AdminCard;

AdminCard.propTypes = {
  data: PropType.object,
  handleClick: PropType.func.isRequired
};
