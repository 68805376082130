import { useEffect, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
import toast from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import Empty from '../empty';
import { useSWRFetch } from '../../hooks';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { ServiceCard, ServiceDetailCard } from '../cards';
import Iconify from '../iconify';
import { ConfirmModal, FormModal } from '../modal';
import { FilterForm, ServiceForm } from '../forms';
import APIService from 'src/service';
import LoadingBackdrop from '../loading/Backdrop';

const drawerWidth = 460;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

const serviceStatus = [
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Terminated',
    value: 'terminated'
  }
];

function ServiceList() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [requestLoading, setRequestLoading] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filterStatus, setFilterStatus] = useState('inactive');
  const [selectedStatus, setSelectedStatus] = useState('unpaid');
  const [filterName, setFilterName] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageLimit, setPageLimit] = useState(6);
  const { data, loading, mutate } = useSWRFetch(
    `/admin/services?page=${pageIndex}&limit=${pageLimit}`
  );
  const [filteredServices, setFilteredServices] = useState([]);
  const pageRows = [6, 12, 24, 30];

  useEffect(() => {
    if (data) {
      if (data?.docs?.length) {
        const mappedData = data?.docs?.map((item) => {
          const subPackage =
            item?.package?.category === 'website'
              ? item?.package?.subPackages?.filter((p) => p?._id === item?.subPackage)[0]
              : null;
          return {
            ...item,
            subPackage
          };
        });
        setFilteredServices(mappedData);
      }
    }
  }, [data]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (data?.docs?.length) {
        setFilteredServices(data?.docs);
      }
    }
  }, [filterName]);

  useEffect(() => {
    if (filterStatus && data?.docs?.length) {
      setFilteredServices(data?.docs?.filter((item) => item?.status === filterStatus));
    }
  }, [filterStatus]);

  const handlePageChange = (e, value) => {
    if (data?.hasNextPage || data?.hasPrevPage) {
      setPageIndex(value + 1);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageLimit(parseInt(e.target.value));
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelected(null);
  };

  const handleAction = (action, item) => {
    setSelected(item);
    if (action === 'View') {
      setOpenDetails(true);
    } else if (action === 'Update') {
      setOpenUpdateForm(true);
    } else {
      setConfirm(true);
    }
    // console.log(action, item);
  };

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(
        data?.docs || [],
        ['businessName', 'amount', 'package.name', 'user.username'],
        {
          caseSensitive: false
        }
      );
      const result = searcher.search(filterName);
      setFilteredServices(result);
    } else {
      setFilteredServices(data?.docs || []);
    }
  };

  const handleServiceUpdate = async () => {
    setRequestLoading(true);

    const response = APIService.update('/admin/service', selected?.id, { status: selectedStatus });

    toast.promise(
      response,
      {
        loading: 'Updating...',
        success: (res) => {
          mutate();
          setOpenUpdateForm(false);
          setSelected(null);
          setRequestLoading(false);
          setSelectedStatus('inactive');
          return `${res?.data?.businessName} service has been updated!`;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to update invoice.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  const handleDelete = async () => {
    setRequestLoading(true);

    setConfirm(false);

    const response = APIService.delete('/admin/service', selected?.id);

    toast.promise(
      response,
      {
        loading: 'Deleting...',
        success: () => {
          filteredServices((prev) => prev?.filter((service) => service?.id !== selected?.id));
          mutate();
          setSelected(null);
          setRequestLoading(false);
          return `${selected?.businessName} service has been deleted!`;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to  delete customer.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  return (
    <>
      {confirm && (
        <ConfirmModal
          open={confirm}
          setOpen={setConfirm}
          handleAction={handleDelete}
          action="DELETE"
        />
      )}
      {requestLoading && <LoadingBackdrop open={requestLoading} setOpen={setRequestLoading} />}
      {openUpdateForm && (
        <FormModal
          open={openUpdateForm}
          setOpen={setOpenUpdateForm}
          title="Update Service Status"
          handleAction={handleServiceUpdate}
        >
          <ServiceForm value={selectedStatus} setValue={setSelectedStatus} />
        </FormModal>
      )}

      <StyledDrawer anchor="right" open={openDetails} onClose={handleCloseDetails}>
        <DrawerHeader>
          <IconButton onClick={handleCloseDetails}>
            <Iconify icon="fe:close" />
          </IconButton>
        </DrawerHeader>
        <ServiceDetailCard data={selected} handleUpdate={() => setOpenUpdateForm(true)} />
      </StyledDrawer>

      <Stack
        component={Card}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, pr: 3 }}
      >
        <UserListToolbar
          filterName={filterName}
          onFilterName={(e) => setFilterName(e.target.value)}
        />
        <FilterForm value={filterStatus} setValue={setFilterStatus} options={serviceStatus} />
      </Stack>

      {filteredServices?.length ? (
        <>
          {loading && <LinearProgress color="secondary" />}

          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {filteredServices?.map((service, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <ServiceCard data={service} handleAction={handleAction} isMobile={isMobile} />
              </Grid>
            ))}
          </Grid>

          <TablePagination
            component="div"
            count={data?.totalDocs || 9}
            page={pageIndex - 1}
            onPageChange={handlePageChange}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={pageRows}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </>
      ) : (
        <>
          {loading && <LinearProgress color="secondary" />}
          <Empty text="No service found" />
        </>
      )}
    </>
  );
}

export default ServiceList;
