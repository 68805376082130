import { useEffect, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
import toast from 'react-hot-toast';
import Card from '@mui/material/Card';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import Empty from '../empty';
import { useSWRFetch } from '../../hooks';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { RequestCard } from '../cards';
import { ConfirmModal, CustomModal, FormModal } from '../modal';
import { FilterForm, FinalizeTransferForm } from '../forms';
import APIService from 'src/service';
import LoadingBackdrop from '../loading/Backdrop';

const requestStatus = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Resolved',
    value: 'resolved'
  },
  {
    label: 'Closed',
    value: 'closed'
  }
];

const requestTypes = [
  {
    label: 'Withdrawal',
    value: 'withdrawal'
  },
  {
    label: 'Deposit',
    value: 'deposit'
  },
  {
    label: 'Update Service',
    value: 'update'
  }
];

function RequestList() {
  const [requestLoading, setRequestLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState('pending');
  const [filterType, setFilterType] = useState('withdrawal');
  const [confirm, setConfirm] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [transferData, setTransferData] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('pending');
  const [filterName, setFilterName] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageLimit, setPageLimit] = useState(6);
  const { data, loading, mutate } = useSWRFetch(
    `/admin/requests?page=${pageIndex}&limit=${pageLimit}`
  );
  const [filteredRequests, setFilteredRequests] = useState([]);
  const pageRows = [6, 12, 24, 30];

  useEffect(() => {
    if (data) {
      if (data?.docs?.length) {
        setFilteredRequests(data?.docs);
      }
    }
  }, [data]);

  useEffect(() => {
    if (filterName) {
      handleFilterSearch();
    } else {
      if (data?.docs?.length) {
        setFilteredRequests(data?.docs);
      }
    }
  }, [filterName]);

  useEffect(() => {
    if (filterStatus && data?.docs?.length) {
      setFilteredRequests(data?.docs?.filter((item) => item?.status === filterStatus));
    }
  }, [filterStatus]);

  useEffect(() => {
    if (filterType && data?.docs?.length) {
      setFilteredRequests(data?.docs?.filter((item) => item?.type === filterType));
    }
  }, [filterType]);

  const handlePageChange = (e, value) => {
    if (data?.hasNextPage || data?.hasPrevPage) {
      setPageIndex(value + 1);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageLimit(parseInt(e.target.value));
  };

  const handleAction = (action, item) => {
    setSelected(item);
    setSelectedStatus(action);
    setConfirm(true);
  };

  const handleFilterSearch = () => {
    if (filterName?.length > 1) {
      const searcher = new FuzzySearch(data?.docs || [], ['amount', 'type', 'user.username'], {
        caseSensitive: false
      });
      const result = searcher.search(filterName);
      setFilteredRequests(result);
    } else {
      setFilteredRequests(data?.docs || []);
    }
  };

  const handleRequestUpdate = async () => {
    setRequestLoading(true);
    setConfirm(false);

    const response = APIService.update('/admin/request', selected?.id, {
      status: selectedStatus,
      type: selected?.type,
      user: selected?.user?._id,
      amount: selected?.amount
    });

    toast.promise(
      response,
      {
        loading: 'Updating...',
        success: (res) => {
          mutate();
          setSelected(null);
          setRequestLoading(false);
          setSelectedStatus('pending');

          if (res.data.status === 'otp') {
            setTransferData(res.data);
            setOpenOtp(true);
          }
          return res?.data?.status === 'otp'
            ? 'Transfer requires OTP to finalize'
            : `${res?.data?.type} request has been updated!`;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to update invoice.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  const handleFinalizeTransfer = async () => {
    setRequestLoading(true);
    const response = APIService.post('/admin/transfer-finalize', transferData);

    toast.promise(
      response,
      {
        loading: 'finalizing transfer...',
        success: (res) => {
          mutate();
          setTransferData(null);
          setRequestLoading(false);
          setSelectedStatus('pending');
          return res?.data?.message;
        },
        error: (err) => {
          setRequestLoading(false);
          return (
            err?.response?.data?.message ||
            err?.message ||
            'Something went wrong, while trying to update invoice.'
          );
        }
      },
      {
        error: {
          duration: 6000
        }
      }
    );
  };

  return (
    <>
      {confirm && (
        <ConfirmModal
          open={confirm}
          setOpen={setConfirm}
          handleAction={handleRequestUpdate}
          action={selectedStatus === 'closed' ? 'DECLINE' : 'APPROVE'}
        />
      )}
      {requestLoading && <LoadingBackdrop open={requestLoading} setOpen={setRequestLoading} />}
      {openOtp && (
        <FormModal
          open={openOtp}
          setOpen={setOpenOtp}
          title="Finalize Transfer"
          handleAction={handleFinalizeTransfer}>
          <FinalizeTransferForm value={otp} setValue={setOtp} />
        </FormModal>
      )}

      <Stack
        component={Card}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, pr: 3 }}>
        <UserListToolbar
          filterName={filterName}
          onFilterName={(e) => setFilterName(e.target.value)}
        />
        <Stack direction="row" spacing={2}>
          <FilterForm value={filterStatus} setValue={setFilterStatus} options={requestStatus} />
          <FilterForm
            value={filterType}
            setValue={setFilterType}
            label="Type"
            options={requestTypes}
          />
        </Stack>
      </Stack>

      {filteredRequests?.length ? (
        <>
          {loading && <LinearProgress color="secondary" />}

          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {filteredRequests?.map((service, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <RequestCard data={service} handleAction={handleAction} />
              </Grid>
            ))}
          </Grid>

          <TablePagination
            component="div"
            count={data?.totalDocs || 9}
            page={pageIndex - 1}
            onPageChange={handlePageChange}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={pageRows}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </>
      ) : (
        <>
          {loading && <LinearProgress color="secondary" />}
          <Empty text="No New Request" />
        </>
      )}
    </>
  );
}

export default RequestList;
