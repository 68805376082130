import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeMode, toggleReceiveNotification } from 'src/store/reducer/lifeCircle';

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingBottom: theme.spacing(4),
  borderRadius: 16
}));

function PreferenceList() {
  const { themeMode, notifyEmail } = useSelector((state) => state.lifeCircle);
  const dispatch = useDispatch();

  const handleChange = (evt) => {
    const value = evt.target.checked ? 'dark' : 'light';
    localStorage.setItem('mode', value);
    dispatch(setThemeMode(value));
    // navigate(0);
  };

  const handleChangeNotification = () => {
    dispatch(toggleReceiveNotification());
  };

  return (
    <>
      <StyledGrid container spacing={2} alignItems="center">
        <Grid item sm={4} xs={12}>
          <Typography variant="h4">Theme</Typography>
          <Typography variant="body2" color="text.secondary">
            Change your theme to dark or light
          </Typography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={themeMode === 'dark'}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Dark Mode"
          />
        </Grid>
      </StyledGrid>
      <Divider light={themeMode === 'dark'} />
      <StyledGrid container spacing={2} alignItems="center">
        <Grid item sm={4} xs={12}>
          <Typography variant="h4">Notifications</Typography>
          <Typography variant="body2" color="text.secondary">
            We'll always let you know about important changes, but you pick what else you want to
            hear about.
          </Typography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={notifyEmail}
                onChange={handleChangeNotification}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="By Email Address"
          />
        </Grid>
      </StyledGrid>
    </>
  );
}

export default PreferenceList;
