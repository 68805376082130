// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// components
import { CustomerList } from 'src/components/list';
import Page from 'src/components/page';

// ----------------------------------------------------------------------
export default function Customers() {
  return (
    <Page title="Customers" sx={{ height: '100%' }}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card sx={{ height: '100%' }}>
          <CustomerList />
        </Card>
      </Container>
    </Page>
  );
}
