import ProtoType from 'prop-types';
import Lightbox from 'react-awesome-lightbox';
// You need to import the CSS only once
import 'react-awesome-lightbox/build/style.css';

function PreviewImage({ image, close }) {
  return <Lightbox image={image} onClose={() => close(false)} />;
}

export default PreviewImage;

PreviewImage.prototype = {
  image: ProtoType.string.isRequired,
  close: ProtoType.func.isRequired
};
