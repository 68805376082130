import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Spacer from '../spacer';
import Empty from '../empty';

const StyledCard = styled(Card)(({ theme }) => ({
  height: 200,
  backgroundColor: theme.palette.primary.main,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: 'center',
  backgroundImage: `url('/assets/images/card-pattern5.png')`,
  backgroundBlendMode: 'color-burn',
  padding: 0
}));

const StyleCardContent = styled(CardContent)(({ theme }) => ({
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

const ColoredTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bolder',
  textTransform: 'capitalize'
}));

BankCard.propTypes = {
  bank: PropType.object
};

function BankCard(props) {
  const { bank } = props;
  return (
    <StyledCard variant="outlined">
      <StyleCardContent>
        {bank ? (
          <>
            <ColoredTypography variant="h4" sx={{ marginTop: 2.5 }}>
              {bank?.accountName}
            </ColoredTypography>
            <Spacer flex />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <ColoredTypography>{bank?.bankName}</ColoredTypography>
              <ColoredTypography>{bank?.accountNumber}</ColoredTypography>
            </Stack>
          </>
        ) : (
          <Empty text="Nothing to display" />
        )}
      </StyleCardContent>
    </StyledCard>
  );
}

export default BankCard;
