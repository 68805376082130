import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Label from '../label';
import { colorVariant, formatCurrency } from 'src/utils';
import Iconify from '../iconify';
import { MoreMenu } from '../menu';
import { useNavigate } from 'react-router-dom';
import { fToNow } from 'src/utils/formatTime';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: 0
}));

const StyledContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const ColoredTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bolder',
  textTransform: 'capitalize'
}));

const options = [
  {
    text: 'View Customer',
    icon: 'ep:user-filled'
  }
];

function RequestCard(props) {
  const { data, handleAction } = props;

  const navigate = useNavigate();

  return (
    <StyledCard>
      <CardHeader
        avatar={
          <Avatar src={data?.user?.photoUrl} alt={data?.user?.username} aria-label="avatar" />
        }
        action={
          <MoreMenu
            options={options}
            handleClick={() =>
              navigate('/dashboard/customers/detail', {
                state: { customer: data?.user, page: 1, fetch: true }
              })
            }
          />
        }
        title={data?.user?.username}
        subheader={data?.user?.emailAddress}
        sx={{
          px: 2,
          py: 2
        }}
      />
      <StyledContent>
        <ColoredTypography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          {data?.type}
        </ColoredTypography>
        <Label variant="ghost" color={colorVariant(data?.status)}>
          {data?.status}
        </Label>
        <Box sx={{ textAlign: 'center', justifyContent: 'center', my: 2 }}>
          <Typography variant="body1" fontWeight={200}>
            Amount
          </Typography>
          <Typography variant="h3">{formatCurrency(data?.amount)}</Typography>
        </Box>
        <ColoredTypography variant="body2" color="text.secondary">
          {fToNow(data?.createdAt)}
        </ColoredTypography>
      </StyledContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button
          color="error"
          size="small"
          startIcon={<Iconify icon="mdi:cancel" />}
          onClick={() => handleAction('closed', data)}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<Iconify icon="bi:check-all" />}
          onClick={() => handleAction('resolved', data)}
        >
          Approve
        </Button>
      </CardActions>
    </StyledCard>
  );
}

export default RequestCard;

RequestCard.propTypes = {
  data: PropType.object,
  handleAction: PropType.func
};
