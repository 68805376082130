import PropType from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
// Third party
import toast from 'react-hot-toast';
// Services
import APIService from '../../service';
// component
import { countries } from '../../utils';
import Iconify from '../iconify';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const usernameExp = /^[a-z0-9]+$/i;

const sex = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  }
];

const roles = [
  {
    label: 'Analyst',
    value: 'analyst'
  },
  {
    label: 'Editor',
    value: 'editor'
  },
  {
    label: 'Manager',
    value: 'manager'
  }
];

const types = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Super Admin',
    value: 'superadmin'
  }
];

const formSchema = Yup.object().shape({
  username: Yup.string()
    .matches(usernameExp, 'Enter a valid username without any special character')
    .trim()
    .lowercase()
    .min(4)
    .max(20)
    .required('Username is required'),
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Enter a valid phone number')
    .required('Phone number is required'),
  emailAddress: Yup.string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  gender: Yup.string(),
  password: Yup.string(),
  role: Yup.string().required('Role is required'),
  type: Yup.string().required('User Type is required')
});

AdminForm.propTypes = {
  data: PropType.object,
  mutate: PropType.func,
  setConfirm: PropType.func
};

function AdminForm(props) {
  const { mutate, data, setConfirm } = props;
  const [loading, setLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountryCode] = useState('+234');
  const [isValidUsername, setIsValidUsername] = useState(null);

  const formik = useFormik({
    initialValues: {
      username: data?.username || '',
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      emailAddress: data?.emailAddress || '',
      phoneNumber: data?.phoneNumber?.replace('+234', '0') || '',
      gender: data?.gender || 'male',
      role: data?.privilege?.role || 'analyst',
      type: data?.privilege?.role || 'admin',
      password: ''
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      setLoading(true);
      const payload = {
        ...values,
        phoneNumber: `${countryCode}${
          values.phoneNumber.charAt(0) === '0'
            ? values.phoneNumber.substring(1)
            : values.phoneNumber
        }`
      };

      payload.privilege = {
        type: payload.type,
        role: payload.role,
        claim: payload.role.includes('analyst') ? 'readonly' : 'read/write'
      };

      if (!payload.password) {
        delete payload.password;
      }

      const response = APIService.update('/admin/update', data?.id, {
        ...payload
      });

      toast.promise(response, {
        loading: 'Updating...',
        success: () => {
          setLoading(false);
          mutate();
          return 'Changes Saved Successfully!';
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        }
      });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChangeCode = (e) => {
    setCountryCode(e.target.value);
  };

  const handleUsernameChange = async (e) => {
    try {
      setIsValidUsername(null);

      const hasError = Boolean(touched.username && errors.username);

      if (!hasError) {
        const response = await APIService.post('/admin/validate-username', {
          username: e.target.value
        });
        if (response.data?.status) {
          setIsValidUsername(true);
        }
      }
    } catch (err) {
      setIsValidUsername(false);
      toast.error(
        err?.response?.data?.message || err?.message || 'Something went wrong, try again.'
      );
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          padding: 15
        }}
      >
        <Stack spacing={2} sx={{ marginBottom: 2, flexGrow: 1 }}>
          <TextField
            fullWidth
            id="outlined-required"
            autoComplete="username"
            label="Username"
            {...getFieldProps('username')}
            onBlur={handleUsernameChange}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi:at" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isValidUsername === false ? (
                    <Iconify icon={'mdi:close-thick'} sx={{ color: 'red' }} />
                  ) : isValidUsername === true ? (
                    <Iconify icon={'mdi:check-bold'} sx={{ color: 'green' }} />
                  ) : null}
                </InputAdornment>
              )
            }}
            onKeyPress={(e) => {
              // eslint-disable-next-line prefer-regex-literals
              const validReg = new RegExp(/^[a-z0-9]+$/i).test(e.key);
              if (!validReg) {
                e.preventDefault();
              }
            }}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="email-address"
            type="email"
            label="Email address"
            {...getFieldProps('emailAddress')}
            error={Boolean(touched.emailAddress && errors.emailAddress)}
            helperText={touched.emailAddress && errors.emailAddress}
          />
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <TextField
              select
              value={countryCode}
              onChange={handleChangeCode}
              sx={{ maxWidth: 80 }}
              SelectProps={{
                native: true
              }}
            >
              {countries.map((option, index) => (
                <option key={index} value={option.code}>
                  {`${option.code} - ${option.name}`}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              autoComplete="phone"
              type="text"
              label="Phone Number"
              {...getFieldProps('phoneNumber')}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
          </Box>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="gender" sx={{ bgcolor: 'background.paper' }}>
                <em>Select your Gender</em>
              </InputLabel>
              <NativeSelect
                input={
                  <OutlinedInput variant="outlined" {...getFieldProps('gender')} id="gender" />
                }
                id="gender"
              >
                {sex.map((gender) => (
                  <option key={gender.value} value={gender.value}>
                    {gender.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Stack>

          <TextField
            fullWidth
            select
            label="Role"
            {...getFieldProps('role')}
            SelectProps={{
              native: true
            }}
            error={Boolean(touched.role && errors.role)}
            helperText={touched.role && errors.role}
          >
            {roles.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="User Type"
            {...getFieldProps('type')}
            SelectProps={{
              native: true
            }}
            error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}
          >
            {types.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>

          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Change Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <div>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            startIcon={<Iconify icon="eva:edit-fill" />}
            loading={loading}
          >
            Save Changes
          </LoadingButton>

          <LoadingButton
            fullWidth
            size="large"
            type="button"
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:person-delete-outline" />}
            sx={{ my: 2 }}
            onClick={() => setConfirm(true)}
          >
            Delete User
          </LoadingButton>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default AdminForm;
