import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducer/auth';
import lifeCircleReducer from './reducer/lifeCircle';
import serviceLayoutReducer from './reducer/serviceLayout';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    lifeCircle: lifeCircleReducer,
    serviceLayout: serviceLayoutReducer
  }
});
