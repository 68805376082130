import PropType from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import toast from 'react-hot-toast';
import APIService from '../../../service';

// ----------------------------------------------------------------------
LoginForm.propTypes = {
  mutate: PropType.func,
  state: PropType.object
};

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required')
});

export default function LoginForm(props) {
  const { mutate, state } = props;
  const [loading, setLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setLoading(true);
      const response = APIService.post('/admin/login', values);

      toast.promise(
        response,
        {
          loading: 'Loading',
          success: (res) => {
            localStorage.setItem('accessToken', res?.data?.accessToken);
            localStorage.setItem('refreshToken', res?.data?.refreshToken);
            mutate();
            setLoading(false);
            return 'Login successful!';
          },
          error: (err) => {
            setLoading(false);
            return (
              err?.response?.data?.message || err?.message || 'Something went wrong, try again.'
            );
          }
        },
        {
          error: {
            duration: 6000
          }
        }
      );
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack spacing={3} sx={{ mb: 3 }}>
          <TextField
            fullWidth
            id="outlined-required"
            autoComplete="username"
            label="Username/Email address"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <LoadingButton fullWidth loading={loading} size="large" type="submit" variant="contained">
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
