import PropType from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Spacer from '../spacer';
import PackageItemCard from './PackageItemCard';

function PackageDetailCard({ data, handleUpdate }) {
  return (
    <Box>
      <Typography variant="h3" textTransform="capitalize">
        {data?.name}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        {data?.desc}
      </Typography>
      <Box
        component="img"
        src={data?.featuredImage}
        alt={data?.category}
        loading="lazy"
        sx={{ width: '100%', height: 120, objectFit: 'cover', borderRadius: 1, my: 3 }}
      />
      {/* <Spacer /> */}
      {data?.negotiable ? (
        <Typography sx={{ mb: 2 }}>This Package is negotiable</Typography>
      ) : data?.subPackages?.length ? (
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ mb: 4 }} spacing={3}>
          {data?.subPackages?.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <PackageItemCard data={item} category="website" />
            </Grid>
          ))}
        </Grid>
      ) : (
        <PackageItemCard data={data} category="other services" />
      )}
    </Box>
  );
}

export default PackageDetailCard;

PackageDetailCard.propTypes = {
  data: PropType.object,
  handleUpdate: PropType.func
};
