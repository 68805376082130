/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @MUI
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import { TabPanel } from 'src/components/tab';
import { BankCard, DebitCard, EarningCard, ProfileCard } from 'src/components/cards';
import { Link } from '@mui/material';
import Iconify from 'src/components/iconify';
import APIService from 'src/service';
import toast from 'react-hot-toast';
import LoadingBackdrop from 'src/components/loading/Backdrop';
import Page from 'src/components/page';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const tabComponent = (tab, data) => {
  switch (tab) {
    case 'Wallet':
      return <EarningCard wallet={data?.wallet} />;
    case 'Debit Card':
      return <DebitCard debitCard={data?.debitCard} />;
    case 'Bank':
      return <BankCard bank={data?.bank} />;
    case 'Referrer':
      return <ProfileCard profile={data?.referrer} />;
    default:
      return null;
  }
};

const tabs = ['Wallet', 'Debit Card', 'Bank', 'Referrer'];

function CustomerDetail() {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [customer, setCustomer] = useState(state?.customer);
  const currentPage = state?.page;

  // console.log('customer', customer);

  useEffect(() => {
    if (state.fetch) {
      handleFetchUser();
    }
  }, [state]);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  const handleFetchUser = async () => {
    try {
      setLoading(true);
      const data = await APIService.fetcher(`/admin/customer/${customer?._id}`);
      if (data) {
        setLoading(false);
        setCustomer(data);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          'Something went try while fetching customer'
      );
    }
  };

  return (
    <Page title={customer?.username} sx={{ height: '100%' }}>
      <Container maxWidth="xl">
        {loading && <LoadingBackdrop open={loading} setOpen={setLoading} />}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              sx={{ display: 'flex', alignItems: 'center' }}
              to={`/dashboard/customers/all?page=${currentPage}`}
            >
              <Iconify icon="eva:arrow-ios-back-fill" />
              Customers
            </Link>
            <Link underline="hover" color="text.secondary" aria-current="page">
              {customer?.username}
            </Link>
          </Breadcrumbs>
        </Stack>

        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ProfileCard profile={customer} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  borderRadius: 1
                }}
              >
                <Tabs value={tab} onChange={handleChange} aria-label="tab">
                  {tabs.map((item, index) => (
                    <Tab key={index} label={item} {...a11yProps(0)} />
                  ))}
                </Tabs>
              </Box>
              {tabs?.map((item, index) => (
                <TabPanel key={item} value={tab} index={index}>
                  {tabComponent(item, customer)}
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

export default CustomerDetail;
