import PropType from 'prop-types';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { Preloader } from 'src/components/loading';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

DashboardLayout.propTypes = {
  loading: PropType.bool.isRequired,
  profile: PropType.object
};

// ----------------------------------------------------------------------

export default function DashboardLayout(props) {
  const { profile, loading } = props;
  const [open, setOpen] = useState(false);

  if (loading) {
    return <Preloader />;
  }

  return (
    <StyledRoot>
      <Header profile={profile} onOpenNav={() => setOpen(true)} />

      <Nav profile={profile} openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
