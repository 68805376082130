import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// material
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Iconify from '../iconify';
// component

// ----------------------------------------------------------------------
MoreMenu.propTypes = {
  options: PropTypes.array.isRequired,
  handleClick: PropTypes.func
};

export default function MoreMenu({ handleClick, options }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const menuOptionClick = (option) => {
    handleClick(option);
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => menuOptionClick(option.text)}
            sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon={option.icon} width={24} />
            </ListItemIcon>
            <ListItemText primary={option.text} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
